import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import luniwave_icon from "../img/Luniwave_icon.png";
import param_icon from "../img/param.svg";
import droplet from "../img/droplet.svg";
import Greenmiles from "../img/logo_GM.png";
import circular from "../img/Circular.svg";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const NavBarHotel = () => {
    const location = useLocation();
    const keycloak = useAuth();
    const { hId, sethId } = useContext(HotelContext);
    const [hotel, setHotel] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const isActiveLuniShower = location.pathname.includes("lunishower");
    const isActiveSettings = location.pathname.includes("settings");
    const isActiveGreenmiles = location.pathname.includes("greenmiles");

    const fetchHotel = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/hotels/${hId}`, {
                method: "get",
                headers: {
                    Authorization: "Bearer " + keycloak.user.access_token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            const data = await response.json();
            setHotel(data.hotel);
            fetchSubscriptions();
        } catch (error) {
            setHasError(true);
            setLoading(false);
        }
    };

    const fetchSubscriptions = async () => {
        try {
            const response = await fetch(`/api/hotels/${hId}/subscriptions/`, {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + keycloak.user.access_token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            setSubscriptions(data.subscription);
        } catch (error) {
            setHasError(true);
        }
    };

    useEffect(() => {
        if (keycloak.isAuthenticated) {
            fetchHotel();
        }
    }, [hId, keycloak.isAuthenticated]);

    function handleClick() {
        //console.log("Suppression du hId");
        sethId(null);
    }

    return (
        <div className="navbar-hotel">
            <div className="logo-section-navbarhotel">
                <img src={luniwave_icon} alt="Logo" className="navbar-logo" />
                <span className="hotel-name">{hotel.hName}</span>
            </div>
            <div className="icon-section">
                <div className="First-button">

                    {/* <Link to="/hotel/greenmiles/dashboard">
                        <Button
                            className={`navbar-icon-button ${
                                isActiveGreenmiles ? "navbar-icon-button-active" : "navbar-icon-button-inactive"
                            }`}
                        >
                            <img
                                src={Greenmiles}
                                alt="greenmiles"
                                className={`navbar-icon ${
                                    isActiveGreenmiles ? "navbar-icon-active" : "navbar-icon-inactive"
                                }`}
                            />
                        </Button>
                    </Link> */}

                    <Link to="/settings/hotel/informations">
                        <Button
                            className={`navbar-icon-button ${
                                isActiveSettings ? "navbar-icon-button-active" : "navbar-icon-button-inactive"
                            }`}
                        >
                            <img
                                src={param_icon}
                                alt="Settings"
                                className={`navbar-icon ${
                                    isActiveSettings ? "navbar-icon-active" : "navbar-icon-inactive"
                                }`}
                            />
                        </Button>
                    </Link>

                    <Link to="/hotel/lunishower/dashboard">
                        <Button
                            className={`navbar-icon-button ${
                                isActiveLuniShower ? "navbar-icon-button-active" : "navbar-icon-button-inactive"
                            }`}
                        >
                            <img
                                src={droplet}
                                alt="Water"
                                className={`navbar-icon ${
                                    isActiveLuniShower ? "navbar-icon-active" : "navbar-icon-inactive"
                                }`}
                            />
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="End-button">
                <Link to="/user/hotels">
                    <Button className="navbar-icon-button-end" onClick={() => handleClick()} >
                        <img src={circular} alt="Changehotel" className="navbar-icon-end" />
                    </Button>
                </Link>
            </div>
            {subscriptions.map((item) => (
                <Button key={item.sId}>{item.fName}</Button>
            ))}
        </div>
    );
};

export default NavBarHotel;
