import React, { useEffect } from "react";
import Luniwave_logo from "../img/icone_beige 1.png";
import { useAuth } from "react-oidc-context";

const Main = () => {
  const keycloak = useAuth();

  const redirectToLogin = () => {
    // Configuration simple de la redirection vers /redirect
    const redirectConfig = {
      redirect_uri: window.location.origin + "/redirect",
    };

    keycloak.signinRedirect(redirectConfig);
  };

  return (
    <div className="Home_Page">
      <div className="homepage_infos">
        <h1 className="homepage_title">Portail Luniwave.</h1>
        <span className="homepage_description">
          Une écologie positive et rentable <br /> pour le secteur du tourisme.
        </span>
        <img src={Luniwave_logo} alt="Luniwave Logo" className="homepage_logo" />
      </div>
      <div className="homepage_connexion">
        <h2 className="homepage_connexion-title">Connexion</h2>
        <p className="homepage_connexion-infos">
          Pas encore client ?{" "}
          <a href="https://www.luniwave.com/contact" target="_blank" rel="noopener noreferrer">
            Nous contacter
          </a>
        </p>
        <button className="homepage_connexion-btn" onClick={redirectToLogin}>
          Se connecter
        </button>

        {/* <p className="homepage_connexion-pc">Politique de confidentialité</p> */}
      </div>
    </div>
  );
};

export default Main;
