import React, { useState, useContext, useEffect } from "react";
import Translation from "utils/Translation";
import { useAuth } from "react-oidc-context";
import { HotelContext } from "context/HotelContext";
import BreadCrumb from "component/Breadcrumb";
import RewardCard from "component/RewardCard";

const Rewards = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);

  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  console.log(keycloak.isAuthenticated);
  const fetchRewardsByHotel = () => {
    console.log('test')
    if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
      setHasError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetch(`/api/hotels/${hId}/rewards`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK" && data.rewards.length > 0) {
          setRewards(data.rewards);
          setHasError(false);
          setNoDataAvailable(false);
          console.log("data.rewards", data.rewards);
        } else {
          setNoDataAvailable(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching rewards:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keycloak.isAuthenticated && keycloak.user && hId) {
      fetchRewardsByHotel();
    }
  }, [keycloak.isAuthenticated, hId]);

  return (
    <div className="Home_Page">
      <BreadCrumb text="Récompenses_title" />
      <h1 className="h1-page">
        <Translation tag={"Récompenses_title"}/>
      </h1>

      {loading ? (
        <p>Chargement des récompenses...</p>
      ) : hasError ? (
        <p>Erreur lors du chargement des récompenses. Veuillez réessayer.</p>
      ) : noDataAvailable ? (
        <p>Aucune récompense disponible.</p>
      ) : (
        <div className="rewards-list">
          {rewards.map((reward) => (
            <RewardCard
              key={reward.rwId}
              name={reward.radName}
              description={reward.radDescription}
              cost={reward.rwCost}
              usageCount={reward.usageCount}
              totalGreenmilesSpent={reward.totalGreenmilesSpent}
            />
          ))}
        </div>
      )}
    </div>
    )
};

export default Rewards;
