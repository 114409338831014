import React, { useState } from "react";
import greenmiles from "../img/logo_GM.png";
import Translation from "utils/Translation";

function ActionRSECard({ name, description, reward, estimatedCost, usageCount, totalGreenMilesEarned, totalGreenMilesDistributed, onToggleDescription, isSelected }) {
  const [showDescription, setShowDescription] = useState(false);
  
  return (
      <div className="room-card">
        <div className="RoomcardElem">
          <span className="RewardTopText TopRoomCardText">
            <Translation tag={"Action_name"} />
            <br />
          </span>
          <span className="BottomRoomCardText">{name}</span>
        </div>
        <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Action_estimated_cost"} />
            <br />
          </span>
          <span className="BottomRoomCardText">
            {estimatedCost} € / ch / mois
          </span>
        </div>
        {/* <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Action_reward"} />
            <br />
          </span>
          <span className="BottomRoomCardText">
            {reward}
            <img src={greenmiles} alt="greenmiles" className="greenmilesLogo" />
          </span>
        </div> */}

{/* Nombre d'interaction */}
        {/* <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Action_usage"} />
            <br />
          </span>
          <span className="BottomRoomCardText">{usageCount}</span>
        </div> */}

        {/* <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Action_total_earned"} />
            <br />
          </span>
          <span className="BottomRoomCardText">
            {totalGreenMilesEarned}
            <img src={greenmiles} alt="greenmiles" className="greenmilesLogo" />
          </span>
        </div> */}
        <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Action_greenmiles_distributed"} />
            <br />
          </span>
          <span className="BottomRoomCardText">
            {totalGreenMilesDistributed}
            <img src={greenmiles} alt="greenmiles" className="greenmilesLogo" />
          </span>
        </div>
        <div className="RoomcardElem">
          <button 
              className="plus-button"
              onClick={onToggleDescription}
            >
              {isSelected ? "−" : "+"}
            </button>
        </div>

        {showDescription && (
          <div className="room-card">
            <div className="RoomcardElem">
              <span className="TopRoomCardText">
                <Translation tag={"Actions_client_description"} />
                <br />
              </span>
              <span className="BottomRoomCardText">{description}</span>
            </div>
          </div>
        )}
      </div>
  );
}

export default ActionRSECard;
