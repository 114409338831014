import React, { useState } from "react";
import Translation from "utils/Translation";
import { segmentHotelMapping } from "../utils/hotelConfig";

const SimulatorForm = ({ formData, setFormData, onSubmit }) => {
    const [submitted, setSubmitted] = useState(false);

  const handleChange = (field) => (e) => {
    setFormData((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const getErrors = () => {
    const errors = {};
    if (!formData.numRooms || formData.numRooms <= 0) errors.numRooms = "Veuillez entrer un nombre de chambres valide.";
    if (!formData.hotelType) errors.hotelType = "Veuillez choisir une marque d'hôtel.";
    if (!formData.occupationRate || formData.occupationRate <= 0) errors.occupationRate = "Veuillez entrer un taux d'occupation valide.";
    if (!formData.energyPrice || formData.energyPrice <= 0) errors.energyPrice = "Veuillez entrer un prix de l'énergie valide.";
    if (!formData.waterPrice || formData.waterPrice <= 0) errors.waterPrice = "Veuillez entrer un prix de l'eau valide.";
    return errors;
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const errors = getErrors();
    if (Object.keys(errors).length === 0) {
      onSubmit();
    }
  };

  return (
    <>
      <section>
        <h1 className="simulator_P1_title">
          <Translation tag={"Simulator_P1_title"} />
        </h1>
        <p className="simulator_P1_description">
          <Translation tag={"Simulator_P1_description"} />
        </p> 
      </section>

      <div className="simulator_P1_form-infos">
        {/* Nombre de chambres */}
        <div className="simulator_P1_form_elem simulator-form">
          <label htmlFor="Nb_rooms">
            <Translation tag={"Simulator_Form_Rooms"} />
          </label>
          <input
            name="Nb_rooms"
            className="simulator-text-input"
            id="Nb_rooms"
            type="number"
            value={formData.numRooms}
            onChange={handleChange("numRooms")}
          />
          {submitted && getErrors().numRooms && (
            <p className="form-error">{getErrors().numRooms}</p>
          )}
        </div>

        {/* Type de marque */}
        <div className="simulator_P1_form_elem simulator-form">
          <label className="simulator_P1_Form_Stars">
            <Translation tag={"Simulator_P1_Form_Stars"} />
          </label>
          <div className="simulator_P1_stars-rating">
            {Object.keys(segmentHotelMapping).map((segment) => (
              <button
                key={segment}
                type="button"
                className={`simulator_P1_star-button ${formData.segment === segment ? "selected" : ""}`}
                onClick={() =>
                  setFormData((prev) => ({
                    ...prev,
                    segment,
                    hotelType: "",
                  }))
                }
              >
                {segment}
              </button>
            ))}
            {submitted && getErrors().segment && (
                <p className="form-error">{getErrors().segment}</p>
            )}

          </div>
        </div>

        {formData.segment && (
          <div className="simulator_P1_form_elem simulator-form">
            <label>Marque choisie dans le segment "{formData.segment}"</label>
            <select
              value={formData.hotelType}
              onChange={handleChange("hotelType")}
            >
              <option value="">-- Choisir une marque --</option>
              {segmentHotelMapping[formData.segment].map((hotel) => (
                <option key={hotel} value={hotel}>
                  {hotel}
                </option>
              ))}
            </select>
            {submitted && getErrors().hotelType && (
                <p className="form-error">{getErrors().hotelType}</p>
            )}
          </div>
        )}

      {/* Taux d'occupation */}
      <div className="simulator_P1_form_elem simulator-form">
        <label>
          <Translation tag={"Simulator_Form_occupationRate"} />
        </label>
        <div className="">
          <input
            name="occupationRate"
            id="occupationRate"
            type="number"
            value={formData.occupationRate}
            onChange={handleChange("occupationRate")}
          />
          <span>%</span>
          {submitted && getErrors().occupationRate && (
            <p className="form-error">{getErrors().occupationRate}</p>
          )}
        </div>
      </div>


        {/* Prix de l'énergie et de l'eau */}
      <div className="simulator_P1_form_elem-price simulator-form">
        <div>
          <label>
            <Translation tag={"Simulator_Form_EnergyPrice"} />
          </label>
          <div className="">
            <input
              name="energyPrice"
              id="energyPrice"
              type="number"
              value={formData.energyPrice}
              onChange={handleChange("energyPrice")}
            />
            <span> € / kWh</span>
            {submitted && getErrors().energyPrice && (
            <p className="form-error">{getErrors().energyPrice}</p>
            )}
          </div>
        </div>

        <div className="">
          <label>
            <Translation tag={"Simulator_Form_WaterPrice"} />
          </label>
          <div className="">
            <input
              name="waterPrice"
              id="waterPrice"
              type="number"
              value={formData.waterPrice}
              onChange={handleChange("waterPrice")}
            />
            <span> € / L</span>
            {submitted && getErrors().waterPrice && (
            <p className="form-error">{getErrors().waterPrice}</p>
            )}
          </div>
        </div>
      </div>

          {/* Envoie des données dans la Page 2 */}
          <button
            className="simulate-button"
            onClick={handleSubmit}
        >
            <Translation tag={"Simulator_P1_Start"} />
            <img
              className="simulator_P1_form_img"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEEUlEQVR4nO2b2WsUWRSHC4yMvrn8C27go0+ODqM48+zukzoPbvhHCI244gxqEBWNe0SFGYdBERE1MTqJ6aTbJIMyhJhEW3GJaTuxTTpJdz45SRUWiSad6nurbrV+cF6aqq57flTdOltZ1nf0AswEVgIR4BIQA54DSWDQtqT9W8w+JmKfM9MKI8A8YDfwCMjhHTk3DuwC5lomA0wGfgNq0cdDYCNQYhnm+HagDf9oBbYFLgTwE9BEcDQCi4NwfCpw3N7AgkbWcBSY4pfzc4EGzOMxMF+388uAbsylC1iqy/kVQC/m0wesU+38WiBLeJC1rlbl/FIgQ/iQO+FXFRFdN+ElBcz26vwUO5QNOxKnTPUiwHGKhyMTdX6xIUGOKsSXH/N1vkRVoNPb38PLZBv9WdmPjHgUJucjwHYVV0v1dHL+/kFO3N3Nuao/ePwixuBg4DfV1nwyuzYVV4q3Pxhy3m1/15/h3YfXBMjTMTNIhvN5JdS23BklgNjJij1U/X+DvoHAQosNYwkQ1S2AY+UPDtP8KpBMuvprzs9ReZXxBHDs+qNy3n98h8+MLq8xXMPzXQCxssp91LbcJZsbwCd2fkmAhqAEcOxyzVESnbJPaafuS6XrXNACOHaz8QrpTLfubHG6W4BVqq9QiABiZ6t+579EVGfssMItgDQijBLAsb+iZbzpeokGdrgFkG6MkQI4sUPFk3/I9PeoXGK5WwBpSRkrwOfY4ZDK2OHzRggkCIEAjl2LS+zQUegSn7kFkMpJaAQQO1W5fyjBKoCkW4CBsAng2IdeqYR7YqAoBEh97FQiQCqMAjxsua3sEUgQIgGu1Bwj0SkNYnWbYIwQCHC6cj/1rVVkc1nlr8FLGC6A5AfdvSltgVAEQwW4+G8pT988QXcovNI0Acoq9lLdfEtnRXm5W4AZJqXD1+IXSKbf4ls6LKhug3kRQHGsPxZRayQMj6IFIkAAleKIMUXRq3WneKsn3x+LOaMEEFTO+I0ngHSLNFd8JlYWF+whRO0CSHGjpy9NQKy3xmmNtaq4Sqzt/ijH/4ye5HVKedStrjUm2BOYBZNMdwwVNsXxM/cO0PS8ltyg0jetFzZbfrbH05ku2juah9rkBtCU92gtsKjIBiTk1luYl/MO9vhpsVBqeRySihN+Gj0NSbmCI89FNwN4D8yyCgFYEpIR2ZFIGvlLQc47AGtCOCq7yvpGh6UzyoelRzwOyivIip/5ny2dALMNHaGNF7zhTfAVecSQYEmCnFLgB8tvgAUqJ8s80JD3CKxGEUqALXam5RctwCZgkmUKDAuxAajR6Hi15POBfy+YZwS5U7ovBcYPWfs/Ip4/fAgaYLrU4KURAVwE6oF2+4PpnG1J+7c6+5gd9jnTgl6/Vex8AofiJ3Puq10mAAAAAElFTkSuQmCC"
              alt="play--v1"
            />
          </button>
      </div>
    </>
  )
}

export default SimulatorForm;