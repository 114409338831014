import FormUser from "component/FormUser";
import { HotelContext } from "context/HotelContext";
import { useAuth } from "react-oidc-context";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ContenerSaaS from "utils/ContenerSaaS";
import Translation from "utils/Translation";

const PersonalSettings = () => {
    const keycloak = useAuth();
    const [user, setUser] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchUser = () => {
            if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
                setHasError(true);
                setLoading(false);
                return;
            }

            setLoading(true);

            fetch("/api/users/me", {
                method: "GET",
                headers: {
                    Authorization: "Bearer" + keycloak.user.access_token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setLoading(false);
                    if(data?.user) {
                        setUser(data.user);
                        setHasError(false);
                    } else {
                        setHasError(true);
                    }
                })
                .catch((err) => {
                    setHasError(true);
                    setLoading(false);
                });
        };

        fetchUser();
        setReload(false);
    }, [reload, keycloak.isAuthenticated]);

    return (
        <ContenerSaaS title="Mon_profil" notMaxHeight>
            <div className="Contenant">
                {!keycloak.isAuthenticated ? (
                    <div>
                        <Translation tag="Connection_required_Info" />
                    </div>
                ) : isLoading ? (
                    <div>
                        <Translation tag="Loader" />
                    </div>
                ) : hasError ? (
                    // <Translation tag="Error_loading_profile" />
                    <div>Une erreur </div>
                ) : (
                    <FormUser user={user} setReload={setReload} />
                )}
            </div>
        </ContenerSaaS>
    );
};

export default PersonalSettings;
