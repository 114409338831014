import React, { useState, useContext, useEffect } from "react";
import Translation from "utils/Translation";
import { useAuth } from "react-oidc-context";
import { HotelContext } from "context/HotelContext";
import BreadCrumb from "component/Breadcrumb";
import ActionRSECard from "component/ActionRSECard";

const Actions = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);

  const [selectedAction, setSelectedAction] = useState(null);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const fetchActionsByHotel = () => {
    if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
      setHasError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetch(`/api/hotels/${hId}/actions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK" && data.actions.length > 0) {
          setActions(data.actions);
          setHasError(false);
          setNoDataAvailable(false);
        } else {
          setNoDataAvailable(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching actions:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keycloak.isAuthenticated && hId) {
      fetchActionsByHotel();
    }
  }, [keycloak.isAuthenticated, hId]);

  const toggleDescription = (actId) => {
    setSelectedAction(selectedAction === actId ? null : actId);
  };

  return (
    <div className="Home_Page">
      <BreadCrumb text="Actions RSE" />
      <h1 className="h1-page">
        <Translation tag={"Actions RSE"}/>
      </h1>

      {loading ? (
        <p>Chargement des actions...</p>
      ) : hasError ? (
        <p>Erreur lors du chargement des actions. Veuillez réessayer.</p>
      ) : noDataAvailable ? (
        <p>Aucune action disponible.</p>
      ) : (
        <div className="actions-list">
          {actions.map((action) => (
            <React.Fragment key={action.actId}>
              <ActionRSECard 
                key={action.actId} 
                name={action.aadName} 
                description={action.aadDescription} 
                reward={action.actReward} 
                estimatedCost={action.actEstimatedCost} 
                usageCount={action.usageCount} 
                totalGreenMilesEarned={action.totalGreenMilesEarned} 
                totalGreenMilesDistributed={action.totalGreenMilesDistributed} 
                onToggleDescription={() => toggleDescription(action.actId)}
                isSelected={selectedAction === action.actId}
              />

              {selectedAction === action.actId && (
                <div className="room-card">
                  <div className="RoomcardElem">
                    <span className="TopRoomCardText">
                      <Translation tag={"Actions_client_description"} />
                      <br />
                    </span>
                    <span className="BottomRoomCardText">{action.aadDescription}</span>
                  </div>
                </div>
                // <div className="description-container">
                //   <div className="description-card">
                //     <h3 className="description-title">DESCRIPTIF CLIENT</h3>
                //     <p className="description-text">{action.aadDescription}</p>
                //   </div>
                // </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default Actions;
