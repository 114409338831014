import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Translation from "utils/Translation";

const TypologyShowersStats = ({ data, temoinKey, essentielKey, experienceKey, isTemperature, showLegend = true, height = 300 }) => {
  const [startIndex, setStartIndex] = useState(0);
  const barsPerPage = 6;

  useEffect(() => {}, [data]);

  if (!data || data.length === 0) {
    return <p>Pas de données disponibles pour le graphique.</p>;
  }

  const handlePrev = () => {
    if (startIndex > 0) setStartIndex(startIndex - 1);
  };

  const handleNext = () => {
    if (startIndex + barsPerPage < data.length) setStartIndex(startIndex + 1);
  };

  const tooltipFormatter = (value, name) => {
    if (name === "Moins de 40L") {
      return [`${value.toLocaleString("fr-FR")}%`, name];
    } else if (name === "Entre 40L et 100L") {
      return [`${value.toLocaleString("fr-FR")}%`, name];
    } else if (name === "Plus de 100L") {
      return [`${value.toLocaleString("fr-FR")}%`, name];
    }
    return [`${value.toLocaleString("fr-FR")}%`, name];
  };

  const displayedData = data.slice(startIndex, startIndex + barsPerPage);

  return (
    <div style={{ position: "relative", width: "100%", height: `${height}px` }}>
      <div
        onClick={handlePrev}
        style={{
          position: "absolute",
          left: "0",
          top: "40%",
          transform: "translateY(-50%)",
          cursor: startIndex === 0 ? "not-allowed" : "pointer",
          opacity: startIndex === 0 ? 0.3 : 1,
          fontSize: "30px",
          padding: "2px",
          zIndex: 1,
          userSelect: "none",
          color: "#346840",
        }}
      >
        ◀
      </div>

      <ResponsiveContainer width="100%" height={height}>
        <BarChart data={displayedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="name" tick={{ fontSize: 14 }} textAnchor="middle" interval={0} />
          <YAxis domain={[0, 100]} ticks={[0, 20, 40, 60, 80, 100]} tickFormatter={(value) => `${value}%`} />

          <Tooltip formatter={tooltipFormatter} />
          {showLegend && <Legend wrapperStyle={{ paddingTop: 10 }} />}

          <Bar dataKey={temoinKey} stackId="a" fill="#106198" name="Moins de 40L" isAnimationActive={false} barSize={30} />
          <Bar dataKey={essentielKey} stackId="b" fill="#365644" name="Entre 40L et 100L" isAnimationActive={false} barSize={30} />
          <Bar dataKey={experienceKey} stackId="c" fill="#90B87B" name="Plus de 100L" isAnimationActive={false} barSize={30} />
        </BarChart>
      </ResponsiveContainer>

      <div
        onClick={handleNext}
        style={{
          position: "absolute",
          right: "0",
          top: "40%",
          transform: "translateY(-50%)",
          cursor: startIndex + barsPerPage >= data.length ? "not-allowed" : "pointer",
          opacity: startIndex + barsPerPage >= data.length ? 0.3 : 1,
          fontSize: "30px",
          padding: "2px",
          zIndex: 1,
          userSelect: "none",
          color: "#346840",
        }}
      >
        ▶
      </div>
    </div>
  );
};

export default TypologyShowersStats;
