import React, { useContext, useEffect, useState } from "react";
//import { Redirect } from "react-router-dom";
import { Navigate, redirect } from "react-router-dom";
//import React from "react";
import replicated from "./replicated.js";
// import { browserHistory } from './react-router'
import { HotelContext } from "context/HotelContext";

import { useAuth } from "react-oidc-context";

const CentralizeRedirect = () => {
  //   if (authFails)
  //     return <Redirect to='/login'  />
  //   }
  const keycloak = useAuth();
  const { hId, sethId } = useContext(HotelContext);
  const [userStatus, setUserStatus] = useState(null);
  //const [isLoading, setIsLoading] = useState(keycloak.isLoading);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (keycloak.isLoading) {
        //setIsLoading(true);
      } else if (keycloak && keycloak.isAuthenticated) {
        try {
          const status = await replicated(keycloak, sethId);
          setUserStatus(status);
        } catch (e) {
          console.error("Error fetching user data:", e);
        } finally {
          //setIsLoading(false);
        }
      } else {
        //setIsLoading(false);
      }
    };

    checkUserStatus();
  }, [keycloak.isAuthenticated, keycloak.isLoading]);

  // Affichage d'un spinner ou autre contenu en attendant que les appels se terminent
  if (keycloak.isLoading) {
    return <div>...</div>;
  }

  // Si l'utilisateur n'est pas authentifié, le rediriger vers la page d'accueil
  if (keycloak && !keycloak.isLoading && !keycloak.isAuthenticated) {
    // Not authenticated, redirect to the home page
    return <Navigate to="/" />;
    //return <>Not connected redirect to home page ...</>;
  }

  // On nettoie le stockage même si on ne l'utilise plus pour la redirection
  sessionStorage.removeItem("postLoginRedirect");
  sessionStorage.removeItem("redirectPath");

  if (userStatus === "created") {
    // Toujours rediriger vers /user/hotels
    return <Navigate to="/user/hotels" />;
  } else if (userStatus === "updated") {
    // Toujours rediriger vers /user/hotels
    return <Navigate to="/user/hotels" />;
  } else if (userStatus === "deleted") {
    return <div>Your account has been deleted, contact the client service</div>;
  } else {
    // Handle other cases or show a loading spinner
    return <div>...</div>;
  }
};

export default CentralizeRedirect;
