/* 
@author : adumoulin
*/

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, Navigate } from "react-router-dom";

// Mes imports
import Home from "../views/Home";

import Slidebar from "../component/Slidebar";
import Slidebar_settings from "../component/Slidebar_settings";

import Navbar from "../utils/Navbar";
import Reglages from "./Reglages";
import { HotelContext } from "../context/HotelContext";
import ChoiceHotel from "./ChoiceHotel";
import AjouterHotel from "./AjouterHotel";
import PersonalSettings from "./PersonalSettings";
import AddGroup from "./AddGroup";
import ContenerSaaS from "utils/ContenerSaaS";
import Inscription from "./Inscription";
import Dashboard from "./Dashboard";
import Consommation from "./consommation";
import Economies from "./economies";
import Rooms from "./rooms";
import Donation from "./donation";
import Room_overview from "./Room_overview";
import Translation from "utils/Translation";
import Customer_xp from "./Customer_xp";
import Simulator from "./simulator.js";
import Abonnement from "./Abonnement";
import Rewards from "./Rewards.js";
import ActionsRSE from "./ActionsRSE";
import Badges from "./Badges";
import Statistics from "./Statistiques";
import Homepage from "./Homepage";

const Base = (props) => {
  const { hId, accessRights } = useContext(HotelContext);
  const location = useLocation();
  const path = location.pathname;
  const hotelIsSelected = Boolean(hId);

  if (props.viewID === "mySimulator") {
    return (
      <div className="App-simulator">
        <Row className="Row_Content">
          <Navbar />
          {path.includes("/user/") || !hotelIsSelected ? (
            <Col xs="3" className="sidebarBloc2">
              <Slidebar_settings viewID={props.viewID} path={path} />
            </Col>
          ) : path.includes("/hotel") || path.includes("/settings") ? (
            <Col xs="3" className="sidebarBloc">
              <Slidebar viewID={props.viewID} path={path} />
            </Col>
          ) : null}
          <Col xs="9" className="mainBloc">
            {/* <div className="mainContent"> */}
            <Simulator />
            {/* </div> */}
          </Col>
        </Row>
      </div>
    );
  }

  if (props.viewID === "homepage") {
    return (
      <div>
        <Homepage />
      </div>
    );
  }

  return (
    <div className="App">
      {props.viewID == "homepage" ? (
        <Homepage />
      ) : (
        <>
          <Row className="Row_Content">
            <Navbar />

            {path.includes("/user/") || !hotelIsSelected ? (
              <Col xs="3" className="sidebarBloc2">
                <Slidebar_settings viewID={props.viewID} path={path} />
              </Col>
            ) : path.includes("/hotel") || path.includes("/settings") ? (
              <Col xs="3" className="sidebarBloc">
                <Slidebar viewID={props.viewID} path={path} />
              </Col>
            ) : null}

            {hId ? (
              <Col xs="9" className="mainBloc">
                <div className="mainContent">
                  {props.viewID === "dashboard" && <Dashboard />}
                  {props.viewID === "consommation" && <Consommation />}
                  {props.viewID === "economies" && <Economies />}
                  {props.viewID === "room" && <Room_overview />}
                  {props.viewID === "rooms" && <Rooms />}
                  {props.viewID === "donation" && (!accessRights?.RSE ? <Navigate to="/hotel/lunishower/dashboard" replace /> : <Donation />)}
                  {props.viewID === "main" && <Home />}
                  {props.viewID === "hotelInformations" && <Reglages />}
                  {props.viewID === "myAccess" && <Home />}
                  {props.viewID === "customer_xp" && (!accessRights.EXP ? <Navigate to="/hotel/lunishower/dashboard" replace /> : <Customer_xp />)}
                  {props.viewID === "rooms_settings" && <Home />}
                  {props.viewID === "devices" && <Home />}
                  {props.viewID === "abonnement" && (!accessRights?.SUB ? <Navigate to="/hotel/lunishower/dashboard" replace /> : <Abonnement />)}
                  {props.viewID === "gmActionsRSE" && <ActionsRSE />}
                  {props.viewID === "gmRewards" && <Rewards />}
                  {props.viewID === "badges" && <Badges />}
                  {props.viewID === "stats" && <Statistics />}
                </div>
              </Col>
            ) : (
              // path.includes("greenmiles") ? (
              //     <Col xs="10" className="mainBloc">
              //         <div className="mainContent">
              //             {props.viewID === "gmActionsRSE" && <ActionsRSE />}
              //             {props.viewID === "gmRewards" && <Rewards />}
              //             {!["gmActionsRSE", "gmRewards"].includes(props.viewID) && (
              //                 <Translation tag="Connection_required_Hotel" />
              //             )}
              //         </div>
              //     </Col>
              // ) :
              <Col xs="10" className="mainBloc">
                <div className="mainContent">
                  {props.viewID === "personalInformations" && <PersonalSettings />}
                  {props.viewID === "addHotel" && <AjouterHotel />}
                  {props.viewID === "myHotels" && <ChoiceHotel />}
                  {props.viewID === "addGroup" && (
                    <ContenerSaaS title="Créer un groupe" notMaxHeight>
                      <AddGroup type={"create"} />
                    </ContenerSaaS>
                  )}
                  {!["personalInformations", "addHotel", "myHotels", "addGroup"].includes(props.viewID) && <Translation tag="Connection_required_Hotel" />}
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default Base;
