import React, { useEffect, useState, useContext } from "react";
import BreadCrumb from "component/Breadcrumb";
import Translation from "utils/Translation";
import { HotelContext } from "context/HotelContext";
import { useAuth } from "react-oidc-context";
import ConsBarChartComponent from "component/ConsBarChartComponent";
import TypologyShowersStats from "component/TypologyShowersStats";

const Statistics = () => {
  const { hId } = useContext(HotelContext);
  const keycloak = useAuth();

  const [statsData, setStatsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [graphSingleData, setGraphSingleData] = useState([]);
  const [totalMoneySaved, setTotalMoneySaved] = useState(0);
  const [averageReduction, setAverageReduction] = useState(0);
  const [hotelStats, setHotelStats] = useState({
    avgTemoinVolume: 0,
    avgTemoinTemp: 0,
    avgEssentielleVolume: 0,
    avgEssentielleTemp: 0,
    avgExperienceVolume: 0,
    avgExperienceTemp: 0,
  });

  useEffect(() => {
    const fetchTotalStats = async () => {
      if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
        return;
      }

      try {
        const response = await fetch(`/api/hotels/${hId}/stats?periodType=beginning`, {
          headers: {
            Authorization: "Bearer " + keycloak.user.access_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log(data);
        if (data.success === "OK") {
          setTotalMoneySaved(data.stats.totalMoneySaved || 0);
          setAverageReduction(data.stats.averageReductionPercentage || 0);

          if (data.stats) {
            setHotelStats({
              avgTemoinVolume: data.stats.averageTemoinConsumption || 0,
              avgTemoinTemp: data.stats.averageTemoinTemp || 0,
              avgEssentielleVolume: data.stats.averageEssentielConsumption || 0,
              avgEssentielleTemp: data.stats.averageEssentielTemp || 0,
              avgExperienceVolume: data.stats.averageExperienceConsumption || 0,
              avgExperienceTemp: data.stats.averageExperienceTemp || 0,
            });
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques totales:", error);
      }
    };

    if (hId && keycloak.isAuthenticated) {
      fetchTotalStats();
    }
  }, [hId, keycloak.isAuthenticated]);

  useEffect(() => {
    const fetchStats = async () => {
      if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
        setHasError(true);
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(`/api/hotels/${hId}/preciseStats`, {
          headers: {
            Authorization: "Bearer " + keycloak.user.access_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log(data);
        if (response.ok) {
          setStatsData(data.data);
          setGraphSingleData([
            {
              name: "Typologies",
              graphAverageTemoinConsumption: data.data.under40L || 0,
              graphAverageEssentielConsumption: data.data.between40And100L || 0,
              graphAverageExperienceConsumption: data.data.between100And250L || 0,
            },
          ]);

          setHasError(false);
        } else {
          setHasError(true);
        }
      } catch (error) {
        console.error("Erreur API :", error);
        setHasError(true);
      }
      setLoading(false);
    };

    if (hId && keycloak.isAuthenticated) {
      fetchStats();
    }
  }, [hId, keycloak.isAuthenticated]);

  if (loading) return <p>Chargement...</p>;
  if (hasError) return <p>Erreur lors du chargement des données</p>;
  if (!statsData) return <p>Aucune donnée disponible</p>;

  const { goalSuccessRate, topClientPercentile, averageSavingsPercent, totalSavingsEuro, savingsPerShowerEuro } = statsData;

  const { avgTemoinVolume, avgTemoinTemp, avgEssentielleVolume, avgEssentielleTemp, avgExperienceVolume, avgExperienceTemp } = hotelStats;

  return (
    <div className="Dashboard">
      <BreadCrumb text="Room_stat" />
      <h1 className="h1-page">
        <Translation tag="Room_stat" />
      </h1>

      {/* SECTION 1 : Trois grosses cards principales */}
      <div className="stats-grid stats-grid-large">
        <div className="stats-card">
          <h3 className="h3-stats-page">
            <Translation tag="goal_success_title" />
          </h3>
          <div className="camembert-circular-progress-container">
            <div
              className="camembert-circular-progress"
              style={{
                background: `conic-gradient(#365644 ${goalSuccessRate * 3.6}deg, #d9d9d9 ${goalSuccessRate * 3.6}deg)`,
              }}
            ></div>
            <p className="camembert-progress-text">{goalSuccessRate || 0}%</p>
          </div>
        </div>
        <div className="stats-card">
          <h3 className="h3-stats-page">
            <Translation tag="shower_types_distribution" />
          </h3>
          <div>
            <TypologyShowersStats data={graphSingleData} temoinKey="graphAverageTemoinConsumption" essentielKey="graphAverageEssentielConsumption" experienceKey="graphAverageExperienceConsumption" isTemperature={false} showLegend={false} height={200} />
          </div>
        </div>
        {topClientPercentile <= 50 && (
          <div className="stats-card stats-card-narrow">
            <h3 className="h3-stats-page-congrats">
              <Translation tag="congrats_title" />
            </h3>
            <p className="stats-percentage">Top {topClientPercentile || 0}%</p>
            <p className="top-text">
              <Translation tag="congrats_subtext" />
            </p>
          </div>
        )}
      </div>

      {/* SECTION 2 : Trois cards rectangulaires */}
      <div className="stats-grid">
        <div className="stats-card">
          <h4 className="h4-stats-page">
            <Translation tag="money_savings" />
          </h4>
          <div className="stats-number-container">
            <span className="stats-value-money">{totalMoneySaved?.toLocaleString("fr-FR") || 0}</span>
            <span className="stats-unit-money">€</span>
          </div>
        </div>

        <div className="stats-card">
          <h4 className="h4-stats-page">
            <Translation tag="shower_consumption_reduction" />
          </h4>
          <div className="stats-number-container">
            <span className="stats-value">{averageReduction?.toLocaleString("fr-FR") || 0}</span>
            <span className="stats-unit">%</span>
          </div>
        </div>

        <div className="stats-card">
          <h4 className="h4-stats-page">
            <Translation tag="money_savings_per_shower" />
          </h4>
          <div className="stats-number-container">
            <span className="stats-value">{savingsPerShowerEuro?.toFixed(2) || 0}</span>
            <span className="stats-unit">€</span>
          </div>
        </div>
      </div>

      {/* SECTION 3 : Données par typologie */}
      <h2 className="h2-page">
        <Translation tag="consumption_by_room_type" />
      </h2>
      <div className="stats-small-wrapper">
        <div className="stats-grid stats-grid-small">
          <div className="stats-card tiny">
            <h4 className="h4-stats-page">
              <Translation tag="avg_temoin_consumption" />
            </h4>
            <div className="stats-number-container">
              <span className="stats-value-blue">{avgTemoinVolume || 0}</span>
              <span className="stats-value-blue">L</span>
            </div>
          </div>
          <div className="stats-card tiny">
            <h4 className="h4-stats-page">
              <Translation tag="avg_temoin_temperature" />
            </h4>
            <div className="stats-number-container">
              <span className="stats-value-blue">{avgTemoinTemp || 0}</span>
              <span className="stats-value-blue">°C</span>
            </div>
          </div>
          <div className="stats-card tiny">
            <h4 className="h4-stats-page">
              <Translation tag="avg_essentielle_consumption" />
            </h4>
            <div className="stats-number-container">
              <span className="stats-value-blue">{avgEssentielleVolume || 0}</span>
              <span className="stats-value-blue">L</span>
            </div>
          </div>
          <div className="stats-card tiny">
            <h4 className="h4-stats-page">
              <Translation tag="avg_essentielle_temperature" />
            </h4>
            <div className="stats-number-container">
              <span className="stats-value-blue">{avgEssentielleTemp || 0}</span>
              <span className="stats-value-blue">°C</span>
            </div>
          </div>
          {avgExperienceVolume > 0 && (
            <div className="stats-card tiny">
              <h4 className="h4-stats-page">
                <Translation tag="avg_experience_consumption" />
              </h4>
              <div className="stats-number-container">
                <span className="stats-value-blue">{avgExperienceVolume}</span>
                <span className="stats-value-blue">L</span>
              </div>
            </div>
          )}
          {avgExperienceTemp > 0 && (
            <div className="stats-card tiny">
              <h4 className="h4-stats-page">
                <Translation tag="avg_experience_temperature" />
              </h4>
              <div className="stats-number-container">
                <span className="stats-value-blue">{avgExperienceTemp}</span>
                <span className="stats-value-blue">°C</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
