import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import Translation from "utils/Translation";
import SavingsBarChartComponent from "component/SavingsBarChartComponent";
import StatCard_simple from "component/Stat_card_simple";
import SmallCard from "component/small_card";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";

const Economies = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);
  const [chartDataSavings, setChartDataSavings] = useState([]);
  const [stats, setStats] = useState({
    totalWaterSaved: 0,
    totalEnergySaved: 0,
    totalMoneySaved: 0,
    totalShowers: 0,
    averageObjectives: 0,
    percentObjectives: 0,
    averageReductionPercentage: 0,
    averageEssentielConsumption: 0,
    averageExperienceConsumption: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedPeriodType, setSelectedPeriodType] = useState("quarter_start");
  const [activeButton, setActiveButton] = useState("quarter_start");
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const formatSavingsChartData = (data) => {
    if (!data || data.length === 0) return [];

    const parsedData = data.map((item) => ({
      ...item,
      date: new Date(`${item.month}-01`),
    }));

    const minDate = new Date(Math.min(...parsedData.map((d) => d.date)));
    const maxDate = new Date(Math.max(...parsedData.map((d) => d.date)));

    const monthsRange = [];
    const currentDate = new Date(minDate);

    while (currentDate <= maxDate) {
      const monthString = currentDate.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
      monthsRange.push(monthString);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    const dataByMonth = parsedData.reduce((acc, item) => {
      const monthKey = item.date.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
      acc[monthKey] = item;
      return acc;
    }, {});

    return monthsRange.map((month) => ({
      month,
      graphTotalEnergySaved: Math.round(dataByMonth[month]?.graphTotalEnergySaved || 0),
      graphTotalWaterSaved: Math.round(dataByMonth[month]?.graphTotalWaterSaved || 0),
    }));
  };

  const fetchStatsByHotel = () => {
    if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
      setHasError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    setNoDataAvailable(false);
    fetch(`/api/hotels/${hId}/stats?periodType=${selectedPeriodType}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          setStats(data.stats);
          setChartDataSavings(formatSavingsChartData(data.chartDataSavings));
          setHasError(false);
        } else if (data.success === "FAIL" && data.message === "No data found for graph.") {
          setNoDataAvailable(true);
          setHasError(false);
          setChartDataSavings([]);
        } else {
          setHasError(true);
          console.error("Error fetching stats:", data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keycloak.isAuthenticated && keycloak.user?.access_token && hId) {
      fetchStatsByHotel();
    }
  }, [keycloak.isAuthenticated, hId, selectedPeriodType]);

  const handlePeriodChange = (periodType) => {
    setSelectedPeriodType(periodType);
    setActiveButton(periodType);
  };

  return (
    <div className="economies">
      <BreadCrumb text="Eco_title" />
      <h1 className="h1-page">
        <Translation tag={"Eco_title"} />
      </h1>
      <div className="button-container">
        <button className={`more-button ${activeButton === "quarter_start" ? "active" : ""}`} onClick={() => handlePeriodChange("quarter_start")}>
          <Translation tag={"since_quarter"} />
        </button>
        <button className={`more-button ${activeButton === "year_start" ? "active" : ""}`} onClick={() => handlePeriodChange("year_start")}>
          <Translation tag={"since_year"} />
        </button>
        <button className={`more-button ${activeButton === "beginning" ? "active" : ""}`} onClick={() => handlePeriodChange("beginning")}>
          <Translation tag={"since_beginning"} />
        </button>
      </div>
      <div className="Dashboard-part-2-bis">
        <div className="Chart_dashboard">
          <h1 className="Title_graph">
            <Translation tag="Savings_period" />
          </h1>

          {loading ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Chargement des données...</p>
            </div>
          ) : hasError ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
            </div>
          ) : noDataAvailable ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Les données ne sont pas encore disponibles pour cette période.</p>
            </div>
          ) : chartDataSavings && chartDataSavings.length > 0 ? (
            <SavingsBarChartComponent data={chartDataSavings} energyKey="graphTotalEnergySaved" waterKey="graphTotalWaterSaved" xAxisKey="month" />
          ) : (
            <div className="chart-loader">
              <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
            </div>
          )}
        </div>
        <div className="stat_simple">
          <StatCard_simple number={noDataAvailable ? "..." : (stats.totalWaterSaved || 0).toLocaleString("fr-FR")} text1="L" text1Color="#deecf7" />
          <StatCard_simple number={noDataAvailable ? "..." : (stats.totalEnergySaved || 0).toLocaleString("fr-FR")} text1="kWh" text1Color="#f0f7de" />
          <StatCard_simple number={noDataAvailable ? "..." : (stats.totalMoneySaved || 0).toLocaleString("fr-FR")} text1="€" text1Color="#fbfcc6" />
        </div>
      </div>
      {/* <h1 className="h1-page">
                <Translation tag="Room_stat" />
            </h1>
            <div className="col_dashboard_number">
                <SmallCard
                    title="Number_Shower"
                    value={noDataAvailable ? "..." : stats.totalShowers.toLocaleString("fr-FR")}
                    unit=""
                />
                <SmallCard
                    title="Percent_obj"
                    // value={`${noDataAvailable ? "..." : stats.percentObjectives.toLocaleString(
                    //     "fr-FR"
                    // )}%`}
                    value={"À venir"}
                    unit=""
                />
                <SmallCard
                    title="Average_obj"
                    // value={`${noDataAvailable ? "..." : stats.averageObjectives.toLocaleString(
                    //     "fr-FR"
                    // )} L`}
                    value={"À venir"}
                    unit=""
                />
            </div> */}
    </div>
  );
};

export default Economies;
