import React, {useState} from "react";
import Translation from "utils/Translation";
import { calculateSimulator } from "../utils/SimCalculator.js";
import SimulatorForm from "component/SimulatorForm.js";
import SimulatorResults from "component/SimulatorResults.js";
import SimulatorBilan from "component/SimulatorBilan.js";

const Simulator = () => {
  const [step, setStep] = useState("form");
  const [formData, setFormData] = useState({
    numRooms: "",
    hotelType: "",
    numStars: 3,
    segment: "",
    heatingType: "electrique",
    occupationRate: "",
    energyPrice: 0.2,
    waterPrice: 4,
    offerType: "achat",
  });
  const [results, setResults] = useState(null);

  // const [numRooms, setNumRooms] = useState("");
  // const [hotelType, setHotelType] = useState("");
  // const [numStars, setNumStars] = useState(1);
  // const [heatingType, setHeatingType] = useState("")
  // const [occupationRate, setOccupationRate] = useState("");
  // const [energyPrice, setEnergyPrice] = useState(0.2);
  // const [waterPrice, setWaterPrice] = useState(4);
  // const [results, setResults] = useState(null);
  // const [offerType, setOfferType] = useState("achat");
  // const hotelStarMapping = {
  //   "Greet": 2,
  //   "Ibis Styles": 3,
  //   "Novotel": 4,
  //   "MGallery": 4,
  //   "Sofitel": 5,
  //   "Molitor": 5,
  // };

  // const selectedStars = hotelType === "Autre" ? Number(numStars) : hotelStarMapping[hotelType] || 1;

  // const formatBreakEven = (months) => {
  //   if (!months || isNaN(months)) return "0 mois"; // Par défaut si non défini
  
  //   const totalMonths = Math.round(months); // Arrondir le nombre de mois
  //   const years = Math.floor(totalMonths / 12); // Nombre d'années
  //   const remainingMonths = totalMonths % 12; // Mois restants

  //   if (years > 0 && remainingMonths > 0) {
  //     return `${years} an${years > 1 ? "s" : ""} et ${remainingMonths} mois`;
  //   } else if (years > 0) {
  //     return `${years} an${years > 1 ? "s" : ""}`;
  //   } else {
  //     return `${remainingMonths} mois`;
  //   }
  // };

  // const handleCalculate = () => {
  //   const results = calculateSimulator({
  //     nbRooms: Number(numRooms),
  //     hotelType: hotelType,
  //     nbStars: selectedStars,
  //     occupationRate: Number(occupationRate),
  //     heatingType: heatingType,
  //     energyPrice: Number(energyPrice),
  //     waterPrice: Number(waterPrice),
  //   });
  //   setResults(results);
  // };

  const handleCalculate = () => {
    if (
        !formData.numRooms ||
        !formData.hotelType ||
        !formData.occupationRate ||
        !formData.energyPrice ||
        !formData.waterPrice
      ) {
        console.warn("Formulaire incomplet !");
        return;
      }

    const results = calculateSimulator({
      nbRooms: Number(formData.numRooms),
      hotelType: formData.hotelType,
      occupationRate: Number(formData.occupationRate),
      heatingType: formData.heatingType,
      energyPrice: Number(formData.energyPrice),
      waterPrice: Number(formData.waterPrice),
    });

    setResults({
      ...results,
      selectedHotel: formData.hotelType,
      occupationRate: Number(formData.occupationRate),
      energyPrice: Number(formData.energyPrice),
      waterPrice: Number(formData.waterPrice),
      nbRooms: Number(formData.numRooms)
    });
    setStep("results");
  };

  const goBackToForm = () => setStep("form");

  return (
    <div className="simulator_P1_container simulator-form">
      {step === "form" && (
        <SimulatorForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleCalculate}
        />
      )}
  
      {step === "results" && (
        <SimulatorResults
          results={results}
          onEdit={goBackToForm}
          onDevisClick={() => setStep("bilan")}
        />
      )}
  
      {step === "bilan" && (
        <SimulatorBilan onEdit={goBackToForm} />
      )}
    </div>
  );  
};

export default Simulator;
