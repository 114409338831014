import Translation from "utils/Translation";
import lunishower from "../img/droplet.svg"
import React from "react";
import greenmiles from "../img/logo_GM.png";
import { useLocation } from "react-router-dom";

function Logo(props) {
    const location = useLocation();

    let logo = lunishower;
    let logoStyle = {};

    if (location.pathname.includes("/greenmiles")) {
        logo = greenmiles;
        logoStyle = { width: "25px", height: "auto" };
    }

    return (
        <div className="logo-container">
            <div className="logo-image">
                <img className="Logo-svg" src={logo} alt="Logo" style={logoStyle}/>
            </div>
            <h1 className="logo-text">
                <Translation tag={props.name} />
            </h1>
    </div>
    );
}

export default Logo;
