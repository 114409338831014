import React, { useContext } from "react";
import ElemSlide from "./ElemSlide";
import Logo from "./Logo";
import Button_icon from "./Button_icon";
import off_svg from "../img/Off_On.svg";
import { useAuth } from "react-oidc-context";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { HotelContext } from "../context/HotelContext";

function Slidebar(props) {
  const { hId, lang, setLang, accessRights } = useContext(HotelContext);
  const location = useLocation();
  const path = location.pathname;
  const hotelIsSelected = hId ? true : false;
  const keycloak = useAuth();

  const handleLogout = () => {
    keycloak.signoutRedirect();
  };

  return (
    <div className="panel-left">
      {path.includes("/settings") ? <Logo name="Reglages" /> : <Logo name="LuniShower" />}

      <div className="grpElemSlide">
        {hotelIsSelected ? (
          path.includes("/settings") ? (
            <>
              <ElemSlide select={props.viewID == "hotelInformations" ? "select" : ""} name="Informations générales" url={"/settings/hotel/informations"} />
              <ElemSlide select={props.viewID == "Rooms" ? "select" : ""} name="Gestion des chambres" url={"/settings/hotel/rooms"} />
              {/* <ElemSlide select={props.viewID == "myAccess" ? "select" : ""} name="Gestion des accès" url={"/settings/hotel/access"} /> */}
              {/* <ElemSlide select={props.viewID == "devices" ? "select" : ""} name="Gestion des appareils" url={"/settings/hotel/devices"} /> */}
            </>
          ) : (
            <>
              <ElemSlide select={props.viewID == "Dashboard" ? "select" : ""} name="Dashboard_title" url={"/hotel/lunishower/dashboard"} />
              <ElemSlide select={props.viewID == "Consommation" ? "select" : ""} name="Conso_title" url={"/hotel/lunishower/consommation"} />
              <ElemSlide select={props.viewID == "Economies" ? "select" : ""} name="Eco_title" url={"/hotel/lunishower/economies"} />
              <ElemSlide select={props.viewID == "badges" ? "select" : ""} name="Badges" url={"/hotel/lunishower/badges"} />
              <ElemSlide select={props.viewID == "stats" ? "select" : ""} name="Room_stat" url={"/hotel/lunishower/stats"} />
              {/* <ElemSlide select={props.viewID == "Rooms" ? "select" : ""} name="Rooms_title" url={"/hotel/lunishower/rooms"} /> */}
              {accessRights && accessRights?.RSE ? <ElemSlide select={props.viewID == "donation" ? "select" : ""} name="donation_title" url={"/hotel/lunishower/donation"} /> : null}
              {accessRights && accessRights?.EXP ? <ElemSlide select={props.viewID == "Customer_xp" ? "select" : ""} name="Customer_xp_title" url={"/hotel/lunishower/customer_xp"} /> : null}
              {accessRights && accessRights?.SUB ? <ElemSlide select={props.viewID == "Abonnement" ? "select" : ""} name="Abonnement" url={"/hotel/lunishower/abonnement"} /> : null}
            </>
          )
        ) : (
          <>
            <ElemSlide select={props.viewID == "myHotels" ? "select" : ""} name="mes_hotels" url={"/user/hotels"} />
            <ElemSlide select={props.viewID == "personalInformations" ? "select" : ""} name="mes_infos" url={"/user/informations"} />
            <ElemSlide select={props.viewID == "statistics" ? "select" : ""} name="Mes statistiques" url={"/user/statistics"} />
            <ElemSlide select={props.viewID == "preferences" ? "select" : ""} name="Mes préférences" url={"/user/preferences"} />
            <ElemSlide select={props.viewID == "accessAPI" ? "select" : ""} name="Accès API" url={"/user/accessAPI"} />
          </>
        )}
      </div>

      <div className="deco_button">
        <Button type="button" className="icon-button-3 langBtn" onClick={() => setLang((lang) => (lang === "fr" ? "en" : "fr"))}>
          {lang.toUpperCase()}
        </Button>
        {/* Bouton d'information */}
        {/* <Button_icon imageSrc={info_svg} onButtonClick={() => {}} /> */}
        {/* Bouton de déconnexion */}
        <Button_icon imageSrc={off_svg} onButtonClick={handleLogout} />
      </div>
    </div>
  );
}

export default Slidebar;
