import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ElemSlide_settings from "./ElemSlide-settings";
import Logo from "./Logo";
import Button_icon from "./Button_icon";
import off_svg from "../img/Off_On.svg";
import info_svg from "../img/Information.svg";
import group from "../img/Group.svg";
import { Button } from "react-bootstrap";
import hotel from "../img/Hotel.svg";
import { useAuth } from "react-oidc-context";
import { HotelContext } from "../context/HotelContext";
import Simulator from "../views/simulator.js";

function Slidebar_settings(props) {
  const { hId } = useContext(HotelContext);
  const { lang, setLang } = useContext(HotelContext);
  const hotelIsSelected = hId ? true : false;
  const keycloak = useAuth();
  const handleLogout = () => {
    // Ici, vous pouvez ajouter d'autres actions avant le logout si nécessaire
    keycloak.signoutRedirect();
  };
  return (
    <div className="panel-left-2">
      <div className="grpElemSlide-settings">
        {/* <ElemSlide_settings
                    select={
                        props.viewID == "personalInformations" ? "select" : ""
                    }
                    img={group}
                    url={"/user/informations"}
                />
                <ElemSlide_settings
                    select={props.viewID == "myHotels" ? "select" : ""}
                    img={hotel}
                    url={"/user/hotels"}
                /> */}

        {/* <ElemSlide_settings
                    select={props.viewID == "mySimulator" ? "select" : ""}
                    img={hotel}
                    url={"/user/simulator"}
                /> */}
      </div>
      <div className="deco_button-2">
        <Button type="button" className="icon-button-3 langBtn" onClick={() => setLang((lang) => (lang === "fr" ? "en" : "fr"))}>
          {lang.toUpperCase()}
        </Button>
        {/* <Button_icon imageSrc={info_svg} onButtonClick={() => {}} /> */}
        <Button_icon imageSrc={off_svg} onButtonClick={handleLogout} />
        <div className="version">v1.3.1</div>
      </div>
    </div>
  );
}

export default Slidebar_settings;
