import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import BreadCrumb from "component/Breadcrumb";
import Translation from "utils/Translation";
import StatCard_simple from "component/Stat_card";
import { useAuth } from "react-oidc-context";
import ConsBarChartComponent from "component/ConsBarChartComponent";

const Consommation = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);

  const [chartDataCons, setChartDataCons] = useState([]);
  const [chartDataTemp, setChartDataTemp] = useState([]);
  const [stats, setStats] = useState({
    averageTemoinConsumption: 0,
    averageEssentielConsumption: 0,
    averageExperienceConsumption: 0,
    averageTemoinTemp: 0,
    averageEssentielTemp: 0,
    averageExperienceTemp: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedPeriodType, setSelectedPeriodType] = useState("quarter_start");
  const [activeButton, setActiveButton] = useState("quarter_start");
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const formatChartData = (data, keys) => {
    if (!data || data.length === 0) return [];

    const parsedData = data.map((item) => ({
      ...item,
      date: new Date(`${item.month}-01`),
    }));

    const minDate = new Date(Math.min(...parsedData.map((d) => d.date)));
    const maxDate = new Date(Math.max(...parsedData.map((d) => d.date)));

    const monthsRange = [];
    const currentDate = new Date(minDate);

    while (currentDate <= maxDate) {
      const monthString = currentDate.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
      monthsRange.push(monthString);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    const dataByMonth = parsedData.reduce((acc, item) => {
      const monthKey = item.date.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
      acc[monthKey] = item;
      return acc;
    }, {});

    return monthsRange.map((month) => ({
      month,
      ...keys.reduce((acc, key) => {
        acc[key] = Math.round(dataByMonth[month]?.[key] || 0);
        return acc;
      }, {}),
    }));
  };

  const fetchStatsByHotel = () => {
    if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
      setHasError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    setNoDataAvailable(false);
    fetch(`/api/hotels/${hId}/stats?periodType=${selectedPeriodType}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          console.log(data);
          setStats(data.stats);
          setChartDataCons(formatChartData(data.chartDataCons, ["graphAverageTemoinConsumption", "graphAverageEssentielConsumption", "graphAverageExperienceConsumption"]));
          setChartDataTemp(formatChartData(data.chartDataTemp, ["graphAverageTemoinTemp", "graphAverageEssentielTemp", "graphAverageExperienceTemp"]));
          setHasError(false);
        } else if (data.success === "FAIL" && data.message === "No data found for graph.") {
          setNoDataAvailable(true);
          setHasError(false);
          setChartDataCons([]);
          setChartDataTemp([]);
          setStats({
            averageTemoinConsumption: 0,
            averageEssentielConsumption: 0,
            averageExperienceConsumption: 0,
            averageTemoinTemp: 0,
            averageEssentielTemp: 0,
            averageExperienceTemp: 0,
          });
        } else {
          setHasError(true);
          console.error("Error fetching stats:", data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keycloak.isAuthenticated && keycloak.user && hId) {
      fetchStatsByHotel();
    }
  }, [keycloak.isAuthenticated, hId, selectedPeriodType]);

  const handlePeriodChange = (periodType) => {
    setSelectedPeriodType(periodType);
    setActiveButton(periodType);
  };

  return (
    <div className="consommation">
      <BreadCrumb text="Conso_title" />
      <h1 className="h1-page">
        <Translation tag="Conso_title" />
      </h1>

      <div className="button-container">
        <button className={`more-button ${activeButton === "quarter_start" ? "active" : ""}`} onClick={() => handlePeriodChange("quarter_start")}>
          <Translation tag={"since_quarter"} />
        </button>
        <button className={`more-button ${activeButton === "year_start" ? "active" : ""}`} onClick={() => handlePeriodChange("year_start")}>
          <Translation tag={"since_year"} />
        </button>
        <button className={`more-button ${activeButton === "beginning" ? "active" : ""}`} onClick={() => handlePeriodChange("beginning")}>
          <Translation tag={"since_beginning"} />
        </button>
      </div>
      <div className="Dashboard-part-2-bis">
        <div className="Chart_dashboard">
          <h1 className="Title_graph">
            <Translation tag="Eau" />
          </h1>
          {loading ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Chargement des données...</p>
            </div>
          ) : hasError ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
            </div>
          ) : noDataAvailable ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Les données ne sont pas encore disponibles pour cette période.</p>
            </div>
          ) : chartDataCons && chartDataCons.length > 0 ? (
            <ConsBarChartComponent data={chartDataCons} temoinKey="graphAverageTemoinConsumption" essentielKey="graphAverageEssentielConsumption" experienceKey="graphAverageExperienceConsumption" isTemperature={false} />
          ) : (
            <div className="chart-loader">
              <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
            </div>
          )}
        </div>
      </div>
      <div className="col_dashboard_number">
        <StatCard_simple number={noDataAvailable ? "..." : (stats.averageTemoinConsumption || 0).toLocaleString("fr-FR")} text1="L" text1Color="#deecf7" text2="moyenne_temoin" />
        <StatCard_simple number={noDataAvailable ? "..." : (stats.averageEssentielConsumption || 0).toLocaleString("fr-FR")} text1="L" text1Color="#deecf7" text2="moyenne_essentielle" />

        {stats.averageExperienceConsumption ? <StatCard_simple number={noDataAvailable ? "..." : stats.averageExperienceConsumption.toLocaleString("fr-FR")} text1="L" text1Color="#deecf7" text2="moyenne_experience" /> : null}
      </div>

      <div className="Dashboard-part-2-bis">
        <div className="Chart_dashboard">
          <h1 className="Title_graph">
            <Translation tag="Temperature" />
          </h1>
          {loading ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Chargement des données...</p>
            </div>
          ) : hasError ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
            </div>
          ) : noDataAvailable ? (
            <div className="chart-loader">
              <p className="chart-loader-text">Les données ne sont pas encore disponibles pour cette période.</p>
            </div>
          ) : chartDataTemp && chartDataTemp.length > 0 ? (
            <ConsBarChartComponent data={chartDataTemp} temoinKey="graphAverageTemoinTemp" essentielKey="graphAverageEssentielTemp" experienceKey="graphAverageExperienceTemp" isTemperature={true} />
          ) : (
            <div className="chart-loader">
              <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
            </div>
          )}
        </div>
      </div>

      <div className="col_dashboard_number">
        <StatCard_simple number={noDataAvailable ? "..." : (stats.averageTemoinTemp || 0).toLocaleString("fr-FR")} text1="°C" text1Color="#f0f7de" text2="moyenne_temoin" />
        <StatCard_simple number={noDataAvailable ? "..." : (stats.averageEssentielTemp || 0).toLocaleString("fr-FR")} text1="°C" text1Color="#f0f7de" text2="moyenne_essentielle" />
        {stats.averageExperienceTemp ? <StatCard_simple number={noDataAvailable ? "..." : (stats.averageExperienceTemp || 0).toLocaleString("fr-FR")} text1="°C" text1Color="#f0f7de" text2="moyenne_experience" /> : null}
      </div>
    </div>
  );
};

export default Consommation;
