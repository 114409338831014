import {
  savingsRatesByHotel,
  waterConsumptionPerShowerByHotel,
  hotelTypeEquivalence
} from "./hotelConfig";

export const calculateSimulator = ({ nbRooms, hotelType, occupationRate, heatingType, energyPrice, waterPrice }) => {

  if (!nbRooms || !hotelType || !occupationRate || !heatingType || !energyPrice || !waterPrice) {
    console.error("Certaines valeurs d'entrée sont manquantes !");
    return null;
  };

  const mainHotelType = hotelTypeEquivalence[hotelType];
  const waterConsumption = waterConsumptionPerShowerByHotel[mainHotelType];
  const savingsRate = savingsRatesByHotel[mainHotelType];

  const performanceCoefficients = 1.1; // Coefficients de performance énergétique
  const personsPerRoom = 1.6;  // Nombre moyen de personnes par chambre
  const showersPerPerson = 1.5;   // Nombre moyen de douches par personne
  const energyFactor = 0.04; // kWh consommé par litre d'eau chauffée
  const daysInMonth = 30; // Nombre de jours par mois

// 🔹 Consommation par nuit
  const waterPerNight = (occupationRate / 100) * nbRooms * personsPerRoom * showersPerPerson * waterConsumption; // Consommation d'eau par nuitée
  const energyPerNight = waterPerNight * energyFactor * performanceCoefficients; // Consommation d’énergie par nuitée

  // Coût de l’eau et de l’énergie par nuitée
  const waterCostPerNight = (waterPerNight / 1000) * waterPrice;
  const energyCostPerNight = energyPerNight * energyPrice;

  // Calculs mensuels
  const waterPerMonth = waterPerNight * daysInMonth;
  const energyPerMonth = energyPerNight * daysInMonth;
  const waterCostPerMonth = waterCostPerNight * daysInMonth;
  const energyCostPerMonth = energyCostPerNight * daysInMonth;

  // Économies estimées
  const waterSaved = waterPerMonth * (savingsRate / 100);
  const energySaved = energyPerMonth * (savingsRate / 100);
  const moneySaved = (waterCostPerMonth + energyCostPerMonth) * (savingsRate / 100);

  // 🔹 Tarifs
  const modulePrice = 125; // Le prix du module à l'achat.
  const platformFee = 2.50; // Le prix de la plateforme & dons par mois.
  const rentalPricePerRoom = 8; // Le prix de location du module par chambre par mois.

  const rentalCostPerMonth = (rentalPricePerRoom * nbRooms); // Coût de la location des modules par chambre.
  const profitPerMonthRent = moneySaved - rentalCostPerMonth;
  const gainAfter3Years = profitPerMonthRent * 12 * 3;

  const purchaseCost = (modulePrice * nbRooms) + (platformFee * nbRooms); // Coût total d'achat des modules par chambre avec abonnement par chambre.
  const platformFeeTotal = (platformFee * nbRooms) // Coût de la plateforme & dons par chambre.
  const coutModule = (modulePrice * nbRooms); // Coût de l'achat des modules par chambre.
  const rentabiltyTime = (coutModule / moneySaved); // Temps avant rentabilisation de l'achat du module.
  const profitPerMonthBuy = moneySaved - platformFeeTotal;

  // Calcul du nombre de mois pour rentabiliser l'investissement
  const breakEvenMonths = (coutModule / (moneySaved - platformFeeTotal)).toFixed(1);

  // 🔹 Bilan environnemental
  const co2Saved = (waterSaved / 1000) / 33;
  const peopleHelped = (waterSaved / 1000) / 7000;

  return {
    nbRooms,
    hotelType: mainHotelType,

    // Consommations
    waterPerMonth: Math.round(waterPerMonth),
    energyPerMonth: Math.round(energyPerMonth),
    waterCostPerMonth: Math.round(waterCostPerMonth),
    energyCostPerMonth: Math.round(energyCostPerMonth),

    // Economies
    waterSaved: Math.round(waterSaved),
    energySaved: Math.round(energySaved),
    moneySaved: Math.round(moneySaved),

    // Offre locative
    rentalCostPerMonth: Math.round(rentalCostPerMonth),
    profitPerMonthRent: Math.round(profitPerMonthRent),
    gainAfter3Years: Math.round(gainAfter3Years),

    // Offre achat
    purchaseCost: Math.round(purchaseCost),
    platformFeeTotal: platformFeeTotal,
    profitPerMonthBuy: Math.round(profitPerMonthBuy),
    rentabiltyTime: Math.round(rentabiltyTime),
    breakEvenMonths: breakEvenMonths,
    coutModule: Math.round(coutModule),

    // Ressources
    co2Saved: Math.round(co2Saved),
    peopleHelped: Math.round(peopleHelped),
  };
};
