import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import Translation from "utils/Translation";
import RoomCard from "component/Roomcard";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";

const Rooms = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const fetchRoomsHotel = () => {
    if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
      setHasError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetch(`/api/hotels/${hId}/rooms`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          const uniqueRooms = Array.from(new Map(data.rooms.map((room) => [room.rRoomNumber, room])).values());
          setRooms(uniqueRooms);
          setHasError(false);
        } else {
          setHasError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keycloak.isAuthenticated && hId && rooms.length === 0) {
      fetchRoomsHotel();
    }
  }, [keycloak.isAuthenticated, hId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const filteredRooms = rooms
    .filter((room) => {
      return room.rRoomNumber.includes(searchTerm) && (selectedTag === "" || room.rTag === selectedTag);
    })
    .sort((a, b) => a.rRoomNumber - b.rRoomNumber);

  return (
    <div className="rooms">
      {/* <BreadCrumb text="Rooms_title" /> */}
      <h1 className="h1-page">
        <Translation tag={"Rooms_title"} />
      </h1>
      <h2 className="h2-page">
        <Translation tag={"Rooms_description"} />
      </h2>

      <div className="filterTags">
        <div className="button-container-tags">
          <label className="more-button-tags-bis">
            <Translation tag={"Filter_by_tag"} />
            <select id="roomTagFilter" name="roomTagFilter" value={selectedTag} onChange={handleTagChange} className="ok">
              <option value="">
                <Translation tag={"All_tags"} />
              </option>
              <option value="Essentielle">Essentielle</option>
              <option value="Expérience">Expérience</option>
              <option value="Témoin">Témoin</option>
            </select>
          </label>
        </div>
      </div>

      {hasError ? <Translation tag={"Error_loading_rooms"} /> : loading ? <Translation tag={"Loading_rooms"} /> : !filteredRooms || filteredRooms.length === 0 ? <Translation tag={"No_rooms"} /> : filteredRooms.map((room) => <RoomCard key={room.rId} number={String(room.rRoomNumber).padStart(2, "0")} floor={Number(room.rFloor) === 0 ? "0" : room.rFloor < 10 ? `0${Number(room.rFloor)}` : room.rFloor} tag={room.rTag} waterSavings={room.rTag === "Témoin" ? "0" : Math.round(Number(room.total_water_savings) / 10) * 10} moneySavings={room.rTag === "Témoin" ? "0" : Math.round(Number(room.total_money_savings))} />)}
    </div>
  );
};

export default Rooms;
