// hotelConfig.js

export const segmentHotelMapping = {
  "Économique": [
    "ibis", "ibis Styles", "ibis Budget",
    "greet", "hotelF1", "JO&JOE"
  ],
  "Milieu de gamme": [
    "Novotel", "Mercure", "Aparthotels Adagio", "Tribe", "Mama Shelter"
  ],
  "Premium": [
    "Pullman", "Mövenpick"
  ],
  "Luxe / Premium Luxe": [
    "Fairmont", "Sofitel", "MGallery"
  ]
};

// On regroupe les marques par leur type principal pour les calculs
export const hotelTypeEquivalence = {
  "ibis": "Ibis Styles",
  "ibis Styles": "Ibis Styles",
  "ibis Budget": "Ibis Styles",
  "greet": "Greet",
  "hotelF1": "Greet",
  "JO&JOE": "Greet",

  "Novotel": "Novotel",
  "Mercure": "Novotel",
  "Aparthotels Adagio": "Novotel",
  "Tribe": "Novotel",
  "Mama Shelter": "Novotel",

  "Pullman": "Molitor",
  "Mövenpick": "Molitor",

  "Fairmont": "Sofitel",
  "Sofitel": "Sofitel",
  "MGallery": "MGallery"
};

export const savingsRatesByHotel = {
  "Greet": 33,
  "Ibis Styles": 34,
  "Novotel": 36,
  "MGallery": 36,
  "Sofitel": 37,
  "Molitor": 42
};

export const waterConsumptionPerShowerByHotel = {
  "Greet": 78,
  "Ibis Styles": 82,
  "Novotel": 93,
  "MGallery": 96,
  "Sofitel": 101,
  "Molitor": 112
};
