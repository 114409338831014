import React from "react";

const SimulatorBilan = ( { onEdit }) => {
    return (
        <div className="simulator_P3_bilan">
            <h2 className="simulator_P3_title">Demande bien enregistrée.</h2>
            <span className="simulator_P3_text">Nous revenons vers vous le plus rapidement possible pour établir un devis précis pour votre établissement et répondre à vos questions.</span>
            <button  className="simulate-button simulator_P3_button" onClick={onEdit}>Faire une nouvelle simulation</button>
        </div>
    );
};

export default SimulatorBilan;
