import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { HotelContext } from "../context/HotelContext";
import NavbarHotel from "./NavbarHotel";
import NavbarUser from "./NavbarUser";
import UserDropdown from "component/Dropdown_pres";
import Avatar from "../img/Avatar.svg";
import { useAuth } from "react-oidc-context";

function Navbar(props) {
    const { hId, sethId } = useContext(HotelContext);
    const keycloak = useAuth();
    const [userFetched, setUserfetched] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [reload, setReload] = useState(false);

    const dropdownItems = [
        // { label: 'Réglages de mon compte', link: '/user/informations' },
        { label: "Déconnexion", action: () => keycloak.signoutRedirect() },
    ];

    const fetchUser = () => {
        setLoading(true);

        fetch("/api/users/me", {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                setUserfetched(data.user);
                // console.log(data.user)
            })
            .catch((err) => {
                console.error("Error fetching user data:", err);
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (keycloak.isAuthenticated) {
            fetchUser();
        } else {
            setUserfetched([]);
        }
    }, [hId, keycloak.isAuthenticated, reload]);

    function handleClick() {
        sethId(null);
    }

    return (
        <Row className="navbarPerso">
            <Col xs="6" className="left">
                {hId ? <NavbarHotel></NavbarHotel> : <NavbarUser></NavbarUser>}
            </Col>
            <Col xs="6" className="col-navbar-right">
                {!keycloak.isAuthenticated ? (
                    <Button
                        className="bouton_hotel_choice"
                        onClick={() => {
                            keycloak.signinRedirect();
                        }}
                    >
                        Se connecter
                    </Button>
                ) : isLoading ? (
                    <div>...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    <UserDropdown
                        username={`${userFetched?.uName} ${userFetched?.uSurname}`}
                        img={Avatar}
                        items={dropdownItems}
                    />
                )}
            </Col>
            {/* <Col xs="6" className="right navBarBtn">
                {!keycloak.isAuthenticated ? (
                    <Button
                        onClick={() => {
                            // On sauvegarde l'url
                            keycloak.signinRedirect;
                        }}
                    >
                        Se connecter
                    </Button>
                ) : isLoading ? (
                    <div>...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    <Nav
                        className="navbar-nav-hover align-items-lg-center"
                        navbar
                    >
                        <Dropdown>
                            <DropdownToggle>
                                <>
                                    <FontAwesomeIcon icon={faHotel} />
                                    <span className="nav-link-inner--text">
                                        {/* {console.log(hotel)} */}
            {/* {hotel.length === 0
                                            ? "Aucun hôtel sélectionné"
                                            : hotel.hName}
                                    </span>
                                </>
                            </DropdownToggle>
                            <DropdownMenu>
                                {hotel.length !== 0 ? (
                                    <DropdownItem
                                        onClick={() => sethId(null)}
                                        tag={Link}
                                        to={"/"}
                                    >
                                        Changer d'hôtel
                                    </DropdownItem>
                                ) : (
                                    <></>
                                )}
                                <DropdownItem
                                    onClick={() => keycloak.signoutRedirect()}
                                    tag={Link}
                                    to={"#"}
                                >
                                    Déconnexion
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                )} */}
            {/* {keycloak.isAuthenticated ? (
                    <>
                        <Nav
                            className="navbar-nav-hover align-items-lg-center"
                            navbar
                        >
                            <Dropdown nav>
                                <DropdownToggle>
                                    <>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                        <span className="nav-link-inner--text">
                                            {/* {keycloak.idTokenParsed.name} 
                                            TEST
                                        </span>
                                    </>
                                </DropdownToggle>
                                {keycloak.isAuthenticated ? (
                                    <DropdownMenu>
                                        <DropdownItem
                                            to="/landing-page"
                                            tag={Link}
                                        >
                                            Mon compte
                                        </DropdownItem>
                                        <DropdownItem
                                            to="/ambassadeurs"
                                            tag={Link}
                                        >
                                            Mode ambassadeur
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => keycloak.signoutRedirect()}
                                            tag={Link}
                                            to={'#'}
                                        >
                                            Déconnexion
                                        </DropdownItem>
                                    </DropdownMenu>
                                ) : null}
                            </Dropdown>
                        </Nav>
                    </>
                ) : null} */}
            {/* </Col> */}
        </Row>
    );
}

export default Navbar;
