import { useParams, Link } from "react-router-dom";
import Slidebar from "../component/Slidebar";
import React, { useState, useEffect } from "react";
import Translation from "utils/Translation";
import SavingsBarChartComponent from "component/SavingsBarChartComponent";
import StatCard_simple from "component/Stat_card_simple";
import { Col, Row } from "react-bootstrap";
import SmallCard from "component/small_card";
import { useAuth } from "react-oidc-context";

function Room_overview(props) {
  const keycloak = useAuth();
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isStartDateManuallySet, setIsStartDateManuallySet] = useState(false);
  const [chartData_consumption, setChartData_consumption] = useState([]);
  const [chartData_economy, setChartData_economy] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [currentRoom, setCurrentRoom] = useState(null);

  const [stats, setStats] = useState({
    numberOfShowers: 0,
    totalWaterSaved: 0,
    totalEnergySaved: 0,
    averageObjectives: 0,
    percentObjectives: 0,
    totalLitres: 0,
    averageTemperature: 0,
    averageConsumption: 0,
  });

  const [showers, setShowers] = useState([]);
  const [douchesConcernees, setDouchesConcernees] = useState();
  const [luniones, setLuniones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const standardShowerLitres = 102;
  const standardShowerTemp = 40;
  let waterSavings = 0;
  let energySavings = 0;
  let objectivesCount = 0;
  let objectivesTotal = 0;
  let waterCons = 0;
  let total_temp = 0;

 const fetchRoomsHotel = () => {
    const hId = localStorage.getItem("hId");
    setLoading(true);
    fetch(`/api/hotels/${hId}/rooms`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          setRooms(data.rooms);
          setHasError(false);
        } else {
          setHasError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  const fetchShowersHotel = () => {
    const hId = localStorage.getItem("hId");
    setLoading(true);
    fetch(`/api/hotels/${hId}/showers`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          setShowers(data.showers);
          setHasError(false);
        } else {
          setHasError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  const fetchLunionesHotel = () => {
    const hId = localStorage.getItem("hId");
    setLoading(true);
    fetch(`/api/luniShower/${hId}/luniOnes`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          setLuniones(data.luniOnes);
          setHasError(false);
        } else {
          setHasError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRoomsHotel();
    fetchShowersHotel();
    fetchLunionesHotel();
  }, [startDate, endDate]);

  const { roomId } = useParams();


  useEffect(() => {
    if (rooms.length > 0) {
      const normalizedRoomNumber = normalizeRoomNumber(roomId);
      const room = rooms.find((room) => room.rRoomNumber === normalizedRoomNumber);
      setCurrentRoom(room);
    }
  }, [rooms, roomId]);
  
  const normalizeRoomNumber = (roomNumber) => {
    if (roomNumber === "00") return "0"; // Garde "00" tel quel
    return roomNumber.replace(/^0+/, ''); // Supprime les zéros de début pour les autres cas
  };
  const findProductNumber = (rRoomNumber, rooms, luniones) => {
    // 1. Trouver le rId associé au rRoomNumber normalisé
    const normalizedRoomNumber = normalizeRoomNumber(rRoomNumber);
    
    const room = rooms.find((room) => room.rRoomNumber === normalizedRoomNumber);
    
    if (!room) return null;
  
    // 2. Trouver le lNumProduct dont le lRId est égal au rId trouvé
    const product = luniones.find((product) => product.lRId === room.rId);
    return product ? product.lNumProduct : null;
  };

  useEffect(() => {
    const lNumProduct = findProductNumber(roomId, rooms, luniones);
  
    const douchesConcernees = showers.filter(
      (douche) =>
        douche.shNUM_PRODUIT === lNumProduct
    );
    
    if (!isStartDateManuallySet) {
      const initialStartDate = showers.reduce((minDate, shower) => {
        const showerDate = shower.shTimeCreate.split(" ")[0];
        return minDate < showerDate ? minDate : showerDate;
      }, showers[0]?.shTimeCreate.split(" ")[0] || new Date().toISOString().split("T")[0]);
  
      setTempStartDate(initialStartDate);
    }
    
    const filteredData = douchesConcernees.filter((shower) => {
      const showerDate = new Date(shower.shTimeCreate.split(" ")[0]);
      return (
        (!startDate || showerDate >= new Date(startDate)) &&
        (!endDate || showerDate <= new Date(endDate))
      );
    });
  
    const monthlySums_economy = {};
    const monthlySums_consumption = {};
  
    filteredData.forEach((shower) => {
      const showerDate = new Date(shower.shTimeCreate.split(" ")[0]);
      const monthKey = `${showerDate.getFullYear()}-${String(showerDate.getMonth() + 1).padStart(2, '0')}`;
  
      if (!monthlySums_economy[monthKey]) {
        monthlySums_economy[monthKey] = {
          waterSaved: 0,
          energySaved: 0,
        };
        monthlySums_consumption[monthKey] = {
          totalWater: 0,
          totalTemp: 0,
          count: 0,
        };
      }
  
      const showerWaterSaving = standardShowerLitres - shower.shCONSO_DOUCHE;
      const showerEnergySaving =
        (standardShowerLitres * (standardShowerTemp - 12) * 1.161) / 1000 -
        (shower.shCONSO_DOUCHE * (shower.shTEMP_DOUCHE - 12) * 1.161) / 1000;
  
      waterSavings += showerWaterSaving;
      waterCons += shower.shCONSO_DOUCHE;
      total_temp += shower.shTEMP_DOUCHE;
      energySavings += showerEnergySaving;
  
      monthlySums_economy[monthKey].waterSaved += showerWaterSaving;
      monthlySums_economy[monthKey].energySaved += showerEnergySaving;
  
      monthlySums_consumption[monthKey].totalWater += shower.shCONSO_DOUCHE;
      monthlySums_consumption[monthKey].totalTemp += shower.shTEMP_DOUCHE;
      monthlySums_consumption[monthKey].count++;
  
      if (shower.shOBJ_DOUCHE > 0) {
        objectivesCount++;
        objectivesTotal += shower.shOBJ_DOUCHE;
      }
    });
  
    const averageObjectives =
      objectivesCount > 0 ? objectivesTotal / objectivesCount : 0;
    const percentObjectives =
      objectivesCount > 0 ? (objectivesCount / filteredData.length) * 100 : 0;
  
    const chartDataWithSums_economy = Object.entries(monthlySums_economy).map(
      ([month, sums]) => ({
        month,
        waterSaved: sums.waterSaved,
        energySaved: Math.round(sums.energySaved),
      })
    );
  
    const chartDataWithSums_consumption = Object.entries(
      monthlySums_consumption
    ).map(([month, sums]) => ({
      month,
      Conso: sums.totalWater,
      Temp: Math.round(sums.totalTemp / sums.count),
    }));
  
    chartDataWithSums_economy.sort(
      (a, b) => new Date(a.month) - new Date(b.month)
    );
    chartDataWithSums_consumption.sort(
      (a, b) => new Date(a.month) - new Date(b.month)
    );
  
    setChartData_consumption(chartDataWithSums_consumption);
    setChartData_economy(chartDataWithSums_economy);
  
    setStats({
      numberOfShowers: filteredData.length,
      totalWaterSaved: Math.round(waterSavings),
      totalEnergySaved: Math.round(energySavings),
      averageObjectives: Math.round(averageObjectives),
      percentObjectives: Math.round(percentObjectives),
      totalLitres: waterCons,
      averageTemperature: Math.round(total_temp / filteredData.length),
      averageConsumption: Math.round(waterCons / filteredData.length),
    });
  }, [showers, luniones, rooms, roomId]);
  const handleApplyDates = () => {
    // Fonction pour mettre à jour startDate et endDate avec les valeurs temporaires
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setIsStartDateManuallySet(true); // Assurez-vous que cette logique est toujours d'actualité
    }
  };

  return (
    <>
      <div className="room_overview">
        <div className="breadcrumb">
          <Link to="/hotel">
            <Translation tag={"Your_hotel"} />
          </Link>{" "}
          /<Link to="/hotel/Lunishower">LuniShower</Link> /
          <Link to="/hotel/Lunishower/rooms">
            <Translation tag={"Rooms_title"} />
          </Link>{" "}
          /
          <div className="last_elem">
            {" "}
            <Translation tag={"Room_nb"} /> {roomId}
          </div>
        </div>
        <div className="Room_dateTitle">
          <div className="Bouton_titre">
            <Link to="/hotel/lunishower/rooms">
                <span >
                  <img className="Room_arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpElEQVR4nO2WvU4CQRDH7yUs/IylhSbKrB8F3AyGxkSND2F2FvH0Wew0GgtLez/eBIy9RjvRTohmOIhouNu9BSIFk2zgLpBf/v+Z2ZkgGMeoBejSHGiMFOM9MNaUwQ85re/xu0NVLswODLjBm9PAeAYGG8rQV+phbCqm63XG+f5UlnFPMb1bgX8OGKorU9z1giqm45aCjNBu9WDoyEMp+kO7rXdVvraPMz72ptm+UilN2dUyXQ4K+qOczu0tY6texpdcuQigMe+uGhviZAoYIxtUGVyU365qVBmVV5LBhu5coPIZP2cB400iWDE9JuToFXS41HZlAZieMxcZYy1Ncb3XnySnqcXxO109cw+G6kMF50xYyA5mfHCxWhl68mir6ugVF8hoG1Y7MR0kgmWeulwgAk3KZe+Kps/UC6Rt44VH/mw2nwZOgz+hun0OML6BLkxawbHqcMtp47DmFZtKhztO0E7IEO97EdAYZYJ2Qoa4j+1ib87gdtBPLEf5CcV4IpXppJLpyjmnzpuJoQow3cot1NpS4k2lKpeD9Km1ZcYR/EN8A08KHew0B5PmAAAAAElFTkSuQmCC" />
                </span>
            </Link>
            <h1 className="h1-page-bis">
              <Translation tag={"Room_nb"}/>
              <span className="Room_nb-space">{" "}</span>
              <span>{roomId}</span>
            </h1>
          </div>

                <div className="date-picker">
                  <span><Translation tag={"Period"} />{" "}</span>
                  <input
                    type="date"
                    className="date_style"
                    value={tempStartDate}
                    onChange={(e) => {
                      setTempStartDate(e.target.value);
                      setIsStartDateManuallySet(true);
                    }}
                  />
                  <span><Translation tag={"To"} /></span>
                  <input
                    type="date"
                    className="date_style"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                  />
                  <button className="button_valid_date" onClick={handleApplyDates}>
                    <Translation tag={"validate"}></Translation>
                  </button>
                </div>
        </div>
        {currentRoom && currentRoom.Tag !== "temoin" && (
          <>
          <h1 className="h1-page">
            <Translation tag={"Room_subtitle_bis"} />
          </h1>
        <div className="Dashboard-part-2-bis">
          <div className="Chart_dashboard">
            <div className="chart-header">
              <h1 className="Title_graph">
                <Translation tag={"Room_graph_title"}></Translation>
              </h1>
            </div>
            <SavingsBarChartComponent
              data={chartData_economy}
              Temp="Energie (Wh)"
              Eau="Eau (L)"
              TempName="energySaved"
              EauName="waterSaved"
              XAxis="month"
            />
          </div>
          <div className="stat_simple">
            <StatCard_simple
              number={stats.totalWaterSaved.toLocaleString("fr-FR")}
              text1="L"
              text1Color="#deecf7"
            >
              {" "}
            </StatCard_simple>
            <StatCard_simple
              number={Math.round(stats.totalEnergySaved)}
              text1="kWh"
              text1Color="#f0f7de"
            >
              {" "}
            </StatCard_simple>
            <StatCard_simple
              number={Math.round(stats.totalEnergySaved * 0.2+stats.totalWaterSaved/1000*4)}
              text1="€"
              text1Color="#f7eede"
            >
              {" "}
            </StatCard_simple>
          </div>
        </div>
        </>
        )}
        <div>
          <h1 className="h2-page">
            <Translation tag={"Room_stat"} />
          </h1>
          <div>
            <div className="col_dashboard_number">
              <SmallCard
                title={<Translation tag={"Room_showersTaken"} />}
                value={stats.numberOfShowers.toLocaleString("fr-FR")}
                unit=""
              />
              <SmallCard
                title={<Translation tag={"Room_averageCons_Shower"} />}
                value={stats.averageConsumption.toLocaleString("fr-FR")}
                unit="L"
              />
              <SmallCard
                title={<Translation tag={"Room_averageTemp"} />}
                value={stats.averageTemperature.toLocaleString("fr-FR")}
                unit="°C"
              />
            </div>

            <h1 className="h1-page">
              <Translation tag={"Room_subtitle"} />
            </h1>
            <div
              className="Dashboard-part-2-bis"
              style={{ paddingBottom: "4em" }}
            >
              <div className="Chart_dashboard">
                <div className="chart-header">
                  <h1 className="Title_graph">
                    <Translation tag={"Room_graph_title_bis"} />
                  </h1>
                </div>
                <SavingsBarChartComponent
                  data={chartData_consumption}
                  Temp="Température (°C)"
                  Eau="Eau(L)"
                  TempName="Temp"
                  EauName="Conso"
                  XAxis="month"
                />
              </div>
              <div className="stat_simple">
                <StatCard_simple
                  number={stats.totalLitres.toLocaleString("fr-FR")}
                  text1="L"
                  text1Color="#deecf7"
                >
                  {" "}
                </StatCard_simple>
                <StatCard_simple
                  number={Math.round(
                    (stats.totalLitres *
                      (stats.averageTemperature - 12) *
                      1.161) /
                      1000
                  )}
                  text1="kWh"
                  text1Color="#f0f7de"
                >
                  {" "}
                </StatCard_simple>
                <StatCard_simple
                  number={Math.round(
                    (stats.totalLitres *
                      (stats.averageTemperature - 12) *
                      1.161) /
                      10000
                  )}
                  text1="Kg eCO2"
                  text1Color="#f7eede"
                >
                  {" "}
                </StatCard_simple>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Room_overview;
