import React, { useState, useEffect, useContext } from "react";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg";
import cloud from "../img/Cloud.svg";
import money from "../img/money.svg";
import StatCard from "component/Stat_card";
import DonationCard from "component/Donation_card";
import bdd_don from "../BDD/bdd_don.json";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";
import { HotelContext } from "context/HotelContext";

const Donation = () => {
  const [stats, setStats] = useState({
    numberOfShowers: 0,
    totalWaterSaved: 0,
  });

  const [overallSaved, setOverallSaved] = useState(0); // 👈 pour la statCard
  const { hId } = useContext(HotelContext);
  const keycloak = useAuth();

  useEffect(() => {
    const fetchWaterSaved = async () => {
      if (!keycloak.isAuthenticated || !keycloak.user?.access_token) return;

      try {
        const response = await fetch(`/api/hotels/${hId}/badges`, {
          headers: {
            Authorization: "Bearer " + keycloak.user.access_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) {
          setOverallSaved(data.data?.stats?.overallWaterSaved || 0);
        }
      } catch (err) {
        console.error("Erreur récupération water saved:", err);
      }
    };

    if (hId && keycloak.isAuthenticated) {
      fetchWaterSaved();
    }
  }, [hId, keycloak.isAuthenticated]);

  const formatDate = (dateString) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };
  const sortedDonations = bdd_don.sort((a, b) => new Date(b.dDeliveryDate) - new Date(a.dDeliveryDate));
  const totalDonations = sortedDonations.length;
  const totalWaterGiven = sortedDonations.reduce((acc, donation) => acc + donation.dWaterGive, 0);

  return (
    <>
      <div className="Donation_global">
        <BreadCrumb text="donation_title" />
        <h1 className="h1-page">
          <Translation tag={"donation_title"} />
        </h1>
        <div className="col_dashboard_number">
          <StatCard number={overallSaved.toLocaleString("fr-FR")} text1="L" text1Color="#deecf7" text2="Eau_reverse" icon={droplet} />

          <StatCard number={Math.ceil(overallSaved / 7000).toLocaleString("fr-FR")} text1="" text1Color="#deecf7" text2="Water_accessed" icon={cloud} />

          <StatCard number={"N/A"} text1="" text1Color="#FFFF" text2="Certificats" icon={money}>
            {" "}
          </StatCard>
        </div>
        <div className="Partie2-donation">
          <h2 className="h2-page">
            <Translation tag={"Donation_certificate"} />
          </h2>

          {/* Liste des certificats */}
          {/* {sortedDonations.length > 0 ? (
            sortedDonations.map((don) => <DonationCard key={don.dNumber} number={don.dNumber.toString()} date={formatDate(don.dDeliveryDate)} periode={`${formatDate(don.dStartDate)} - ${formatDate(don.dEndDate)}`} liters={don.dWaterGive.toLocaleString("fr-FR")} link={don.dlink} />)
          ) : (
            <p>
              <Translation tag={"No_donation"} />
            </p>
          )} */}

          <div className="Partie2-donation">
            <p className="badge-text" style={{ fontStyle: "italic", color: "#365644" }}>
              Vos certificats seront bientôt disponibles.
            </p>
            {/* <DonationCard number={"Exemple"} date={"01-01-1990"} periode={`01-01-1990 - 01-01-1990`} liters={"60"} link={"https://luniwave.com"} don={cloud} /> */}
          </div>
        </div>
        <h2 className="h2-page">
          <Translation tag={"Learn_More_Water_Donation"} />
        </h2>
        <div className="MadeBlueFoundation">
          <h3 className="Foundation-title">
            <Translation tag={"Made_Blue_Found"} />{" "}
          </h3>
          <div className="Description_Madeblue">
            <p className="MadeBlueText" style={{ textAlign: "justify" }}>
              <Translation tag={"Made_Blue_description"}></Translation>
            </p>
            <a href="https://madeblue.org/en/">
              <button className="MadeBlueMore">
                <Translation tag={"See_more"}></Translation>
              </button>{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donation;
