import React, { useState, useEffect, useContext } from "react";
import { HotelContext } from "context/HotelContext";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg";
import cloud from "../img/Cloud.svg";
import money from "../img/money.svg";
import LineChartComponent_simple from "component/Linecomponent_simple";
import StatCard from "component/Stat_card";
import { Link } from "react-router-dom";
import BreadCrumb from "component/Breadcrumb";
import { useAuth } from "react-oidc-context";

const Dashboard = () => {
  const keycloak = useAuth();
  const { hId } = useContext(HotelContext);

  const [chartData, setChartData] = useState([]);
  const [stats, setStats] = useState({
    numberOfShowers: 0,
    totalWaterSaved: 0,
    totalMoneySaved: 0,
    averageReductionPercentage: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedPeriodType, setSelectedPeriodType] = useState("quarter_start");
  const [activeButton, setActiveButton] = useState("quarter_start");
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const formatChartData = (data) => {
    return data.map((item) => {
      const [year, month] = item.month.split("-");
      const date = new Date(`${year}-${month}-01`);
      const formattedDate = date.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });
      return {
        ...item,
        month: formattedDate, // ou item.date si c’est l’attribut utilisé
      };
    });
  };

  const fetchStatsByHotel = () => {
    if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
      setHasError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    setNoDataAvailable(false);
    fetch(`/api/hotels/${hId}/stats?periodType=${selectedPeriodType}`, {
      method: "get",
      headers: {
        Authorization: "Bearer " + keycloak.user.access_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === "OK") {
          setStats(data.stats);
          setChartData(formatChartData(data.chartData));
          setHasError(false);
        } else if (data.success === "FAIL" && data.message === "No data found for graph.") {
          setNoDataAvailable(true);
          setHasError(false);
          setChartData([]);
          setStats({
            numberOfShowers: 0,
            totalWaterSaved: 0,
            totalMoneySaved: 0,
            averageReductionPercentage: 0,
          });
        } else {
          setHasError(true);
          console.error("Error fetching stats:", data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setHasError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (keycloak.isAuthenticated && keycloak.user && hId) {
      fetchStatsByHotel();
    }
  }, [keycloak.isAuthenticated, hId, selectedPeriodType]);

  // Ici est géré le changement de date.
  const handlePeriodChange = (periodType) => {
    setSelectedPeriodType(periodType);
    setActiveButton(periodType);
  };

  // // Ici est géré l'affichage de la date.
  const formatDate = (dateString) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  return (
    <>
      <div className="Dashboard">
        <BreadCrumb text="Dashboard_title" />
        <h1 className="h1-page">
          <Translation tag={"Dashboard_title"} />
        </h1>
        <div className="button-container">
          <button className={`more-button ${activeButton === "quarter_start" ? "active" : ""}`} onClick={() => handlePeriodChange("quarter_start")}>
            <Translation tag={"since_quarter"} />
          </button>
          <button className={`more-button ${activeButton === "year_start" ? "active" : ""}`} onClick={() => handlePeriodChange("year_start")}>
            <Translation tag={"since_year"} />
          </button>
          <button className={`more-button ${activeButton === "beginning" ? "active" : ""}`} onClick={() => handlePeriodChange("beginning")}>
            <Translation tag={"since_beginning"} />
          </button>
        </div>
        <div className="col_dashboard_number">
          <StatCard number={noDataAvailable ? "..." : (stats.totalWaterSaved || 0).toLocaleString("fr-FR")} text1="L" text1Color="#deecf7" text2="Water_savings" icon={droplet}>
            {" "}
          </StatCard>
          <StatCard number={noDataAvailable ? "..." : (stats.averageReductionPercentage || 0).toLocaleString("fr-FR")} text1="%" text1Color="#f0f7de" text2="perc_savings" icon={cloud}>
            {" "}
          </StatCard>
          <StatCard number={noDataAvailable ? "..." : (stats.totalMoneySaved || 0).toLocaleString("fr-FR")} text1="€" text1Color="#fbfcc6" text2="Money_savings" icon={money}>
            {" "}
          </StatCard>
        </div>
        <div className="Dashboard-part-2">
          <div className="Chart_dashboard">
            <div className="chart-header">
              <h1 className="Title_graph">
                <Translation tag={"Dashboard_period"} />
              </h1>
              <Link to="/hotel/lunishower/economies">
                <button className="more-button">
                  <Translation tag={"See_more"} /> <span className="more-arrow">→</span>
                </button>
              </Link>
            </div>

            {loading ? (
              <div className="chart-loader">
                <p className="chart-loader-text">Chargement des données...</p>
              </div>
            ) : hasError ? (
              <div className="chart-loader">
                <p className="chart-loader-text">Erreur lors du chargement des données. Veuillez réessayer.</p>
              </div>
            ) : noDataAvailable ? (
              <div className="chart-loader">
                <p className="chart-loader-text">Les données ne sont pas encore disponibles pour cette période.</p>
              </div>
            ) : chartData && chartData.length > 0 ? (
              <LineChartComponent_simple data={chartData} />
            ) : (
              <div className="chart-loader">
                <p className="chart-loader-text">Pas de données disponibles pour cette période.</p>
              </div>
            )}
          </div>
          {/*
          <div className="Donation">
            <div className="chart-header">
              <h1 className="Title_graph">
                <Translation tag={"donation_title"} />
              </h1>
              {/* <Link to="/hotel/lunishower/donation">
                            </Link> 
            </div>
            <div className="chart-loader-donation">
              <p className="chart-loader-text">Les données ne sont pas encore disponibles.</p>
            </div>
            {/* {bdd_don.length > 0 ? (
                            bdd_don.slice(0, 3).map((don) => (
                                
                                <CertificateCard
                                    key={don.dNumber}
                                    certificateNumber={don.dNumber.toString()}
                                    dateIssued={formatDate(don.dDeliveryDate)}
                                    // onDownloadClick={don.dlink}
                                />
                            ))
                        ) : (
                            <p>
                                <Translation tag={"No_donation"} />
                            </p>
                        )} 
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
