import React, { useEffect, useState, useContext } from "react";
import BreadCrumb from "component/Breadcrumb";
import MonthlyBadges from "component/MonthlyBadges";
import Translation from "utils/Translation";
import { HotelContext } from "context/HotelContext";
import { useAuth } from "react-oidc-context";
import silverBadge from "../img/SilverBadge.png";
import greenBadge from "../img/GreenBadge.png";

const Badges = () => {
  const { hId, lang } = useContext(HotelContext);
  const keycloak = useAuth();

  const [badgeData, setBadgeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchBadges = async () => {
      if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
        setHasError(true);
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(`/api/hotels/${hId}/badges`, {
          headers: {
            Authorization: "Bearer " + keycloak.user.access_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) {
          setBadgeData(data.data);
          console.log("dta :", data.data);
          setHasError(false);
        } else {
          setHasError(true);
        }
      } catch (error) {
        console.error("Erreur API :", error);
        setHasError(true);
      }
      setLoading(false);
    };

    if (hId && keycloak.isAuthenticated) {
      fetchBadges();
    }
  }, [hId, keycloak.isAuthenticated]);

  if (loading) return <p>Chargement...</p>;
  if (hasError) return <p>Erreur lors du chargement des données</p>;
  if (!badgeData) return <p>Aucune donnée disponible</p>;

  const { monthlyBadges, milestones, co2Milestones, energyMilestones, stats } = badgeData;
  const overallWaterSaved = stats?.overallWaterSaved || 0;
  const overallCO2Saved = stats?.overallCO2Saved || 0;
  const overallEnergySaved = stats?.overallEnergySaved || 0;

  const getLastMonthBadge = () => {
    if (!Array.isArray(monthlyBadges) || monthlyBadges.length === 0) return null;

    const now = new Date();
    const prevMonthDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const prevMonthStr = prevMonthDate.toISOString().slice(0, 7);

    let badge = monthlyBadges.find((b) => b.month === prevMonthStr);

    if (!badge) {
      const currentMonthStr = now.toISOString().slice(0, 7);
      badge = monthlyBadges.find((b) => b.month === currentMonthStr);
    }

    if (!badge) {
      badge = [...monthlyBadges].sort((a, b) => b.month.localeCompare(a.month))[0];
    }

    return badge;
  };

  const lastMonthBadge = getLastMonthBadge();
  const allMilestones = milestones || [];
  const allCO2Milestones = co2Milestones || [];
  const allEnergyMilestones = energyMilestones || [];

  const previousMilestone = [...allMilestones].reverse().find((m) => m.reached);
  const nextMilestone = allMilestones.find((m) => !m.reached) || null;

  const previousCO2Milestone = [...allCO2Milestones].reverse().find((m) => m.reached);
  const nextCO2Milestone = allCO2Milestones.find((m) => !m.reached) || null;

  const previousEnergyMilestone = [...allEnergyMilestones].reverse().find((m) => m.reached);
  const nextEnergyMilestone = allEnergyMilestones.find((m) => !m.reached) || null;

  const currentMilestone = nextMilestone || { value: overallWaterSaved };
  const currentCO2Milestone = nextCO2Milestone || { value: overallCO2Saved };
  const currentEnergyMilestone = nextEnergyMilestone || { value: overallEnergySaved };

  const previousValue = previousMilestone?.value || 0;
  const progressMax = currentMilestone.value - previousValue;
  const progressVal = overallWaterSaved - previousValue;
  const milestoneProgress = progressMax > 0 ? (progressVal / progressMax) * 100 : 0;

  const previousCO2Value = previousCO2Milestone?.value || 0;
  const progressCO2Max = currentCO2Milestone.value - previousCO2Value;
  const progressCO2Val = overallCO2Saved - previousCO2Value;
  const milestoneCO2Progress = progressCO2Max > 0 ? (progressCO2Val / progressCO2Max) * 100 : 0;

  const previousEnergyValue = previousEnergyMilestone?.value || 0;
  const progressEnergyMax = currentEnergyMilestone.value - previousEnergyValue;
  const progressEnergyVal = overallEnergySaved - previousEnergyValue;
  const milestoneEnergyProgress = progressEnergyMax > 0 ? (progressEnergyVal / progressEnergyMax) * 100 : 0;

  return (
    <div className="Dashboard">
      <BreadCrumb text="Badges" />
      <h1 className="h1-page">
        <Translation tag="Badges" />
      </h1>

      <section>
        <h2 className="h2-page">
          <Translation tag="LastMonth_Badges" />
        </h2>
        <MonthlyBadges reductionBadge={lastMonthBadge?.reductionBadge} consumptionBadge={lastMonthBadge?.consumptionBadge} reductionThresholds={stats?.reductionThresholds} consumptionThresholds={stats?.consoThresholds} />
      </section>

      <section>
        <h2 className="h2-page">
          <Translation tag="Accomplishments_Badges" />
        </h2>
        <h2 className="h25-page">
          <Translation tag="LastMonth_Badges_WaterSaved" />
        </h2>

        <div className="milestone-container-wrapper">
          <div className="milestone-container">
            <div className="milestone-left">
              <img src={greenBadge} alt="badge" className="milestone-icon" />
              <div className="milestone-label">{previousMilestone ? `Palier ${previousMilestone.level}` : "Aucun palier"}</div>
              <div className="milestone-value">{previousMilestone ? previousMilestone.value.toLocaleString() + " L" : "0 L"}</div>
            </div>

            <div className="milestone-bar-wrapper">
              <div className="progress-bar">
                <div className="progress-fill" style={{ width: `${milestoneProgress}%` }}></div>
              </div>
            </div>

            <div className="milestone-right">
              <img src={silverBadge} alt="prochain badge" className="milestone-icon milestone-next" />
              <div className="milestone-label">{nextMilestone ? `Palier ${nextMilestone.level || "?"}` : "Objectif atteint"}</div>
              <div className="milestone-value">{nextMilestone ? nextMilestone.value.toLocaleString() + " L" : "-"}</div>
            </div>
          </div>

          <div className="milestone-description-grid">
            <div className="milestone-left-text">
              <p className="badge-text">
                <Translation tag="SinceStart_Badges" /> <strong>{overallWaterSaved.toLocaleString()} L</strong>.
              </p>
              <p className="badge-text">{previousMilestone ? previousMilestone.description?.[lang] || "🎉 Bravo pour ce palier atteint !" : lang === "fr" ? "Commencez à économiser pour débloquer votre premier palier et afficher votre impact 🌱" : "Start saving to unlock your first milestone and showcase your impact 🌱"}</p>
            </div>
            <div className="milestone-right-text">
              <p className="badge-text">{nextMilestone ? (lang === "fr" ? `Prochain objectif à ${nextMilestone.value.toLocaleString()} litres économisés.` : `Next milestone at ${nextMilestone.value.toLocaleString()} liters saved.`) : lang === "fr" ? "Tous les paliers ont été atteints, bravo !" : "All milestones reached, congratulations!"}</p>
            </div>
          </div>
        </div>

        <h2 className="h25-page">
          <Translation tag="LastMonth_Badges_CO2Saved" />
        </h2>
        <div className="milestone-container-wrapper">
          <div className="milestone-container">
            <div className="milestone-left">
              <img src={greenBadge} alt="badge" className="milestone-icon" />
              <div className="milestone-label">{previousCO2Milestone ? `Palier ${previousCO2Milestone.level}` : "Aucun palier"}</div>
              <div className="milestone-value">{previousCO2Milestone ? previousCO2Milestone.value.toLocaleString() + " kg CO₂" : "0 kg CO₂"}</div>
            </div>

            <div className="milestone-bar-wrapper">
              <div className="progress-bar">
                <div className="progress-fill" style={{ width: `${milestoneCO2Progress}%` }}></div>
              </div>
            </div>

            <div className="milestone-right">
              <img src={silverBadge} alt="prochain badge" className="milestone-icon milestone-next" />
              <div className="milestone-label">{nextCO2Milestone ? `Palier ${nextCO2Milestone.level || "?"}` : "Objectif atteint"}</div>
              <div className="milestone-value">{nextCO2Milestone ? nextCO2Milestone.value.toLocaleString() + " kg CO₂" : "-"}</div>
            </div>
          </div>

          <div className="milestone-description-grid">
            <div className="milestone-left-text">
              <p className="badge-text">
                <Translation tag="SinceStart_Badges" /> <strong>{overallCO2Saved.toLocaleString()} kg CO₂</strong>.
              </p>
              <p className="badge-text">{previousCO2Milestone ? previousCO2Milestone.description?.[lang] || "🎉 Bravo pour ce palier atteint !" : lang === "fr" ? "Commencez à économiser pour débloquer votre premier palier et afficher votre impact 🌱" : "Start saving to unlock your first milestone and showcase your impact 🌱"}</p>
            </div>
            <div className="milestone-right-text">
              <p className="badge-text">{nextCO2Milestone ? (lang === "fr" ? `Prochain objectif à ${nextCO2Milestone.value.toLocaleString()} kg CO₂ économisés.` : `Next milestone at ${nextCO2Milestone.value.toLocaleString()} kg CO₂ saved.`) : lang === "fr" ? "Tous les paliers ont été atteints, bravo !" : "All milestones reached, congratulations!"}</p>
            </div>
          </div>
        </div>

        <h2 className="h25-page">
          <Translation tag="LastMonth_Badges_EnergySaved" />
        </h2>
        <div className="milestone-container-wrapper">
          <div className="milestone-container">
            <div className="milestone-left">
              <img src={greenBadge} alt="badge" className="milestone-icon" />
              <div className="milestone-label">{previousEnergyMilestone ? `Palier ${previousEnergyMilestone.level}` : "Aucun palier"}</div>
              <div className="milestone-value">{previousEnergyMilestone ? previousEnergyMilestone.value.toLocaleString() + " kWh" : "0 kWh"}</div>
            </div>

            <div className="milestone-bar-wrapper">
              <div className="progress-bar">
                <div className="progress-fill" style={{ width: `${milestoneEnergyProgress}%` }}></div>
              </div>
            </div>

            <div className="milestone-right">
              <img src={silverBadge} alt="prochain badge" className="milestone-icon milestone-next" />
              <div className="milestone-label">{nextEnergyMilestone ? `Palier ${nextEnergyMilestone.level || "?"}` : "Objectif atteint"}</div>
              <div className="milestone-value">{nextEnergyMilestone ? nextEnergyMilestone.value.toLocaleString() + " kWh" : "-"}</div>
            </div>
          </div>

          <div className="milestone-description-grid">
            <div className="milestone-left-text">
              <p className="badge-text">
                <Translation tag="SinceStart_Badges" /> <strong>{overallEnergySaved.toLocaleString()} kWh</strong>.
              </p>
              <p className="badge-text">{previousEnergyMilestone ? previousEnergyMilestone.description?.[lang] || "🎉 Bravo pour ce palier atteint !" : lang === "fr" ? "Commencez à économiser pour débloquer votre premier palier et afficher votre impact 🌱" : "Start saving to unlock your first milestone and showcase your impact 🌱"}</p>
            </div>
            <div className="milestone-right-text">
              <p className="badge-text">{nextEnergyMilestone ? (lang === "fr" ? `Prochain objectif à ${nextEnergyMilestone.value.toLocaleString()} kWh économisés.` : `Next milestone at ${nextEnergyMilestone.value.toLocaleString()} kWh saved.`) : lang === "fr" ? "Tous les paliers ont été atteints, bravo !" : "All milestones reached, congratulations!"}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Badges;
