// SavingsBarChartComponent.js
import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import Translation from 'utils/Translation';

const SubscriptionBarChartComponent = ({ data }) => {
  useEffect(() => {
  }, [data]);

  if (!data || data.length === 0) {
      return <p>Pas de données disponibles pour le graphique.</p>;
  }

  const tooltipFormatter = (value, name, props) => {
    let unit = "€";

    if(name.props.tag === 'SubGraphLegend_totalSavings'){
      return [`Économies totale réalisées : ${value.toLocaleString("fr-FR")} ${unit}`, name];
    } else if(name.props.tag === 'SubGraphLegend_realCost'){
        return [`Coût selon contrat : ${value.toLocaleString("fr-FR")} ${unit}`, name];
    } else if(name.props.tag === 'SubGraphLegend_regularization'){
        return [`Régularisation : ${value.toLocaleString("fr-FR")} ${unit}`, name];
    } else if(name.props.tag === 'SubGraphLegend_provCost'){
        return [`Coût provisionné : ${value.toLocaleString("fr-FR")} ${unit}`, name];
    } 
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip formatter={tooltipFormatter} />
        <Legend />
        <Bar
          dataKey="totalSavings"
          stackId="a"
          fill="#346840"
          name={<Translation tag={"SubGraphLegend_totalSavings"} />}
          isAnimationActive={false}
          barSize={30}
        />
        <Bar
          dataKey="provCost"
          stackId="c"
          fill="#106198"
          name={<Translation tag={"SubGraphLegend_provCost"} />}
          isAnimationActive={false}
          barSize={30}
        />
        <Bar
          dataKey="realCost"
          stackId="b"
          fill="#848484"
          name={<Translation tag={"SubGraphLegend_realCost"} />}
          isAnimationActive={false}
          barSize={30}
        />
        <Bar
          dataKey="regularization"
          stackId="b"
          fill="rgb(218, 175, 123)"
          name={<Translation tag={"SubGraphLegend_regularization"} />}
          isAnimationActive={false}
          barSize={30}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SubscriptionBarChartComponent;
