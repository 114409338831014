import React from "react";
import bed from "../img/bed.svg";
import Translation from "utils/Translation";

function RoomCard({ number, floor, tag, waterSavings, moneySavings }) {
  return (
    <div className="room-card">
      <div className="room-icon">
        {" "}
        <img src={bed} alt="bed"></img>
      </div>
      <div className="RoomcardElem">
        <span className="TopRoomCardText">
          N°
          <br />
        </span>
        <span className="BottomRoomCardText">{number}</span>
      </div>
      <div className="RoomcardElem">
        <span className="TopRoomCardText">
          <Translation tag={"Room_floor"} />
          <br />
        </span>
        <span className="BottomRoomCardText">{floor}</span>
      </div>
      <div className="RoomcardElem">
        <span className="TopRoomCardText">
          Version LuniShower
          <br />
        </span>
        <span className="BottomRoomCardText">{tag}</span>
      </div>
      {/* <div className="RoomcardElem">
        <span className="TopRoomCardText">
          <Translation tag={"Room_savings"} />
          <br />
        </span>
        <span className="BottomRoomCardText">{waterSavings} L</span>
      </div>
      <div className="RoomcardElem">
        <span className="TopRoomCardText">
          <Translation tag={"Gain"} />
          <br />
        </span>
        <span className="BottomRoomCardText">{moneySavings} €</span>
      </div> */}
    </div>
  );
}

export default RoomCard;
