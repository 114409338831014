import React, { useState } from 'react';

const formatBreakEven = (months) => {
    if (!months || isNaN(months)) return "N/A";
  
    const totalMonths = Math.round(parseFloat(months));
    const years = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;
  
    if (years > 0 && remainingMonths > 0) {
      return `${years} an${years > 1 ? "s" : ""} et ${remainingMonths} mois`;
    } else if (years > 0) {
      return `${years} an${years > 1 ? "s" : ""}`;
    } else {
      return `${totalMonths} mois`;
    }
  };

const formatNumberWithSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const roundToTen = (number) => {
    return Math.round(number / 10) * 10;
};

const roundToUnit = (number) => {
    return Math.round(number);
};

const formatCO2 = (number) => {
    return Number(number).toFixed(2);
};

const getBilanByPeriod = (results, period) => {
    const multiplier = {
      "1 mois": 1,
      "1 an": 12,
      "5 ans": 60
    }[period];
  
    return {
      waterSaved: formatNumberWithSpaces(roundToTen(Math.round(results.waterSaved * multiplier))),
      energySaved: formatNumberWithSpaces(roundToTen(Math.round(results.energySaved * multiplier))),
      co2Saved: formatCO2(results.co2Saved * multiplier),
      peopleHelped: formatNumberWithSpaces(roundToUnit(results.peopleHelped * multiplier)),
    };
  };

const SimulatorResults = ({ results, onEdit, onDevisClick }) => {
    const [mode, setMode] = useState('locative');
    const [period, setPeriod] = useState('1 mois');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const bilan = getBilanByPeriod(results, period);

    // Debug logs
    console.log('Results object:', results);
    console.log('Hotel Type:', results.hotelType);
    console.log('Occupation Rate:', results.occupationRate);
    console.log('Energy Price:', results.energyPrice);
    console.log('Water Price:', results.waterPrice);
    console.log('Number of Rooms:', results.nbRooms);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleDevisClick = async () => {
        // Réinitialiser l'erreur
        setEmailError('');

        // Validation de l'email
        if (!email) {
            setEmailError('Veuillez entrer une adresse email');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Veuillez entrer une adresse email valide');
            return;
        }

        setIsLoading(true);

        // Préparation des données
        const dataToSend = {
            email,
            hotelBrand: results.selectedHotel,
            occupationRate: results.occupationRate,
            energyPrice: results.energyPrice,
            waterPrice: results.waterPrice,
            nbRooms: results.nbRooms
        };

        // Debug log before sending
        console.log('Données à envoyer:', dataToSend);

        try {
            const response = await fetch('/api/devis', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Erreur serveur:', errorData);
                throw new Error(errorData.message || 'Erreur lors de l\'envoi du devis');
            }

            // Si tout s'est bien passé, on appelle la fonction de navigation
            onDevisClick();
        } catch (error) {
            console.error('Error sending devis:', error);
            setEmailError('Une erreur est survenue lors de l\'envoi du devis. Veuillez réessayer.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="simulator_P2_header">
                <div className="simulator_P2_back" onClick={onEdit}>
                    <img
                        className="simulator_P2_return_img"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEjElEQVR4nO1b3W4bRRhdqUEtd5S+AqVIXHJFKaIVcA2Unyt+LgpFfQgkqyovUCHUB6CKlAeIKkX7nWlsz6x/4iQWVana2o0jaCXUtGkKceKfQZ9r6JK6yXp3ZmcdONJIVuyd7Dn7zcz5Zr71vP9hF6W5uSNSiI8UkJNE0wpYkEQtRbQmifrchp9bw+/4Nzm+hq/1JhFF4DUFfK+IFiVRTwE6ThtcS1STwIWC7x/zsoxqtfqCJPpKAaW4hPcUBAik738JYMrLEnEFnJNETVvER0RGQxJ961yIQIi3FVE9LeIjImJZASdSJy6lfFEBlwYTmCPyoWjgifRHAIdSIV/w/WMSWHJNfEQ0XCv5/utWyReJ3lVEj1yT3UWE9UCIU3bIAx8qok3XJCOIsCV9/zOj5KUQnyqg65rcGK2riD42Qj4Q4pQC2hkgNXYkKCHeT+7oKLtjPoIID5UQR2ORB3CIraxrEokbUZ2X7bEFUMAl5zdvLhJ+GJf8iSyYHGMCEPWLvn88auhPZcHoVAoF/Uu9rhekNNMnUZ3zlihP/5xL4oEQ+s7Nm7rb7WpGt9PRS+Wymb6JzkZJaZuuyP9cq+k/Njb0TrQaDSP9S+D2rhmkfJLPOwn3u6urWvf7ehR+XVkxF2HAF88Pf6Jy2uRvXb+ut7e3RxK3IYAikiPJl+bmXk2T+HKloh+tr+9K3IoAgB65vaZ4Dy8F4qX5+QGh/nPCPQ0BJNH5Z8c/7C99vKxttduRiVsUoLIz/I8k2b3dqy0GgX5w//7YxG0JwNliPp8//DT8ff+0lXC/elWvNpu61+vFJm9JAM37G15o/OdshHt7czMRcZsCKOC78Po/bapjtq6/37tnhLhlAX4KR8CCCQvbDFnYrAvwr4lQEq0m6eza4qL+8/Fj48RtCqCAlfAS+DBuR7+1WtaIWxWAaC1sgTtxOlkqlayTtyhAJ7EANaX2hwAywRBo3LiReJ13PgRkwkkwqdNzPgkqA8tgEq+fhWVw2mW2lwUjlDP9D+qVit6ImO+7t8JiUMRkbcens8eOjwsBAqIPngpw5crLNtPharGYKD+wng4z0jgGi2uZjQtAVPZ2QgIXbAsQTpp6YyRNFiIg53xTtDaGdzAtAHN9RgCGzRq/JN4hlW1xBhchpi1AFO9gOAI+9/Y4Gmu4EGE375Da0RiDKzBdCRBOsDqdzoA8ewjONQwJ8LU3Kcfj5fn5wbLJ54ZG+iSqRy6tlURv7bMCiV5BiDcjkf8bXH7q+saNNaKLXswiqdrEP31gOVaRFIMNA5efTvCTfyCBV7wkUMDJSSiRHfHktyTwnmcCAfDJxJXK+v5p779YLK2AtvFi6fBwSLKDnMqYJ3rHswklxNFMltA+ebss2YQ3zhLJ5adZMEvDV+suzs7OHvTSRkD0hovKsn/IA0uRS2BtAcCUAr7hTCtF8rck0ZmZmZkDXlYAYIqLECWRsjjOJefzzt8XjOQgic7z6UtC/9Ad9pGL/eKDa+Tz+cO8B88HEQq4LIGqAu4MX5juDSexNf7bkOxl/i1fA+Al1/fv7Xf8BZSBr748enldAAAAAElFTkSuQmCC"
                        alt="Retour"
                    />
                    <span>Modifier mes informations</span>
                </div>
            </div>

            <h2 className="simulator_P2_main-title">Résultat de votre simulation</h2>

            <div className="simulator_P2_infos">      
                <h5 className="simulator_P2_section-title">Votre établissement</h5>
                <div className="simulator_P2_infos-row">
                    <p>
                        Nombre de chambre : <span className="simulator_P2_highlight"> {results.nbRooms} chambres
                        </span>
                    </p>
                    <p>
                        Votre marque Accor : <span className="simulator_P2_highlight">{results.selectedHotel}
                        </span>
                    </p>
                </div>
                <p className="simulator_P2_reference"> Hôtel de référence pour les calculs d'économie : <span className="simulator_P2_highlight">{results.hotelType}</span>
                </p>
            </div>

            <div className="simulator_P2_row">
            {/* Bloc Rentabilité */}
                <div className="simulator_P2_column">
                    <h5 className="simulator_P2_section-title">Votre rentabilité</h5>
                    <div className="simulator_P2_row-renta">
                        <button
                            className={`simulator_P2_button ${mode === 'locative' ? 'active' : ''}`}
                            onClick={() => setMode('locative')}
                        >
                            Offre Locative
                        </button>
                        <button
                            className={`simulator_P2_button ${mode === 'achat' ? 'active' : ''}`}
                            onClick={() => setMode('achat')}
                        >
                            Offre Achat
                        </button>
                    </div>

                    {mode === 'locative' ? (
                        <div className="simulator_P2_column-renta">
                            <div className="simulator_P2_row">
                            <div className="simulator_P2_row-renta-bloc">
                                <span className="simulator_P2_highlight-renta">{formatNumberWithSpaces(roundToTen(results.moneySaved))} €</span>
                                <p>Gain par mois avec la solution Lunishower</p>
                            </div>
                            <div className="simulator_P2_row-renta-bloc">
                                <span className="simulator_P2_highlight-renta">{formatNumberWithSpaces(roundToTen(results.rentalCostPerMonth))} €</span>
                                <p>Coût par mois de la solution</p>
                            </div>
                            </div>
                            <div className="simulator_P2_row">
                            <div className="simulator_P2_row-renta-bloc">
                                <span className="renta">{results.profitPerMonthRent >= 0 ? `+ ${formatNumberWithSpaces(roundToTen(results.profitPerMonthRent))} €` : `- ${formatNumberWithSpaces(roundToTen(Math.abs(results.profitPerMonthRent)))} €`}</span>
                                <p>Profit & loss / mois</p>
                            </div>
                            <div className="simulator_P2_row-renta-bloc">
                                <span className="simulator_P2_highlight-renta">{formatNumberWithSpaces(roundToTen(results.gainAfter3Years))} €</span>
                                <p>Gain d'argent après 3 ans d'équipement</p>
                            </div>
                            </div>
                        </div>
                    ) : (
                        <div className="simulator_P2_column-renta">
                            <div className="simulator_P2_row">
                                <div className="simulator_P2_row-renta-bloc">
                                    <span className="simulator_P2_highlight-renta">{formatNumberWithSpaces(roundToTen(results.moneySaved))} €</span>
                                    <p>Gain par mois avec la solution Lunishower</p>
                                </div>
                                <div className="simulator_P2_row-renta-bloc">
                                    <span className="simulator_P2_highlight-renta">{formatNumberWithSpaces(roundToTen(results.purchaseCost))} €</span>
                                    <p>Investissement à l'équipement</p>
                                </div>
                                <div className="simulator_P2_row-renta-bloc">
                                    <span className="simulator_P2_highlight-renta">{formatNumberWithSpaces(roundToTen(results.platformFeeTotal))} €</span>
                                    <p>Coût par mois pour l'accès à la plateforme, les dons et la maintenance</p>
                                </div>
                            </div>
                            <div className="simulator_P2_row">
                                <div className="simulator_P2_row-renta-bloc">
                                    <span className="renta ">{results.profitPerMonthBuy >= 0 ? `+ ${formatNumberWithSpaces(roundToTen(results.profitPerMonthBuy))} €` : `- ${formatNumberWithSpaces(roundToTen(Math.abs(results.profitPerMonthBuy)))} €`} €</span>
                                    <p>Profit & loss / mois</p>
                                </div>
                                <div className="simulator_P2_row-renta-bloc">
                                    <span className="simulator_P2_highlight-renta">{formatBreakEven(results.breakEvenMonths)}</span>
                                    <p>Retour sur investissement</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Bloc Bilan mensuel */}
                <div className="simulator_P2_column simulator_P2_summary">
                    <h5 className="simulator_P2_section-title">Votre bilan</h5>
                    <div className="simulator_P2_row-renta">
                        <button
                            className={`simulator_P2_button ${period === '1 mois' ? 'active' : ''}`}
                            onClick={() => setPeriod('1 mois')}
                        >
                            1 mois
                        </button>
                        <button
                            className={`simulator_P2_button ${period === '1 an' ? 'active' : ''}`}
                            onClick={() => setPeriod('1 an')}
                        >
                            1 an
                        </button>
                        <button
                            className={`simulator_P2_button ${period === '5 ans' ? 'active' : ''}`}
                            onClick={() => setPeriod('5 ans')}
                        >
                            5 ans
                        </button>
                    </div>
                    <div className="simulator_P2_column-bilan">
                        <div className="simulator_P2_bilan-circle">
                            <span><span className="bilan-value">{bilan.energySaved}</span> <span className="bilan-unit">kWh</span></span>
                            <span className="bilan-description">économisés</span>
                        </div>
                        <div className="simulator_P2_bilan-circle">
                            <span><span className="bilan-value">{bilan.waterSaved}</span> <span className="bilan-unit">L</span></span>
                            <span className="bilan-description">économisés</span>
                        </div>
                        <div className="simulator_P2_bilan-circle">
                            <span><span className="bilan-value">{bilan.co2Saved}</span> <span className="bilan-unit">t eCO2</span></span>
                            <span className="bilan-description">économisés</span>
                        </div>
                        <div className="simulator_P2_bilan-circle">
                            <span><span className="bilan-value">{bilan.peopleHelped}</span></span>
                            <span className="bilan-description">personnes en plus avec l'accès à l'eau</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="devis-container">
                <span className="devis-text">
                    Pour aller plus loin de l'équipement de Lunishower pour votre établissement.
                    Précisez-nous simplement votre adresse mail. Nous vous enverrons cette simulation ainsi qu'un devis personnalisé.
                </span>
                <div className='simulator_P2_devis'>
                    <div className="email-input-container">
                        <input 
                            className={`simulator_P2_devis-email ${emailError ? 'error' : ''}`} 
                            type="email" 
                            placeholder="Votre adresse mail"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError('');
                            }}
                        />
                        {emailError && <p className="email-error">{emailError}</p>}
                    </div>
                    <button 
                        className="simulate-button" 
                        onClick={handleDevisClick}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Envoi en cours...' : 'Demander un devis précis'}
                        {!isLoading && (
                            <img
                                className="simulator_P1_form_img"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEEUlEQVR4nO2b2WsUWRSHC4yMvrn8C27go0+ODqM48+zukzoPbvhHCI244gxqEBWNe0SFGYdBERE1MTqJ6aTbJIMyhJhEW3GJaTuxTTpJdz45SRUWiSad6nurbrV+cF6aqq57flTdOltZ1nf0AswEVgIR4BIQA54DSWDQtqT9W8w+JmKfM9MKI8A8YDfwCMjhHTk3DuwC5lomA0wGfgNq0cdDYCNQYhnm+HagDf9oBbYFLgTwE9BEcDQCi4NwfCpw3N7AgkbWcBSY4pfzc4EGzOMxMF+388uAbsylC1iqy/kVQC/m0wesU+38WiBLeJC1rlbl/FIgQ/iQO+FXFRFdN+ElBcz26vwUO5QNOxKnTPUiwHGKhyMTdX6xIUGOKsSXH/N1vkRVoNPb38PLZBv9WdmPjHgUJucjwHYVV0v1dHL+/kFO3N3Nuao/ePwixuBg4DfV1nwyuzYVV4q3Pxhy3m1/15/h3YfXBMjTMTNIhvN5JdS23BklgNjJij1U/X+DvoHAQosNYwkQ1S2AY+UPDtP8KpBMuvprzs9ReZXxBHDs+qNy3n98h8+MLq8xXMPzXQCxssp91LbcJZsbwCd2fkmAhqAEcOxyzVESnbJPaafuS6XrXNACOHaz8QrpTLfubHG6W4BVqq9QiABiZ6t+579EVGfssMItgDQijBLAsb+iZbzpeokGdrgFkG6MkQI4sUPFk3/I9PeoXGK5WwBpSRkrwOfY4ZDK2OHzRggkCIEAjl2LS+zQUegSn7kFkMpJaAQQO1W5fyjBKoCkW4CBsAng2IdeqYR7YqAoBEh97FQiQCqMAjxsua3sEUgQIgGu1Bwj0SkNYnWbYIwQCHC6cj/1rVVkc1nlr8FLGC6A5AfdvSltgVAEQwW4+G8pT988QXcovNI0Acoq9lLdfEtnRXm5W4AZJqXD1+IXSKbf4ls6LKhug3kRQHGsPxZRayQMj6IFIkAAleKIMUXRq3WneKsn3x+LOaMEEFTO+I0ngHSLNFd8JlYWF+whRO0CSHGjpy9NQKy3xmmNtaq4Sqzt/ijH/4ye5HVKedStrjUm2BOYBZNMdwwVNsXxM/cO0PS8ltyg0jetFzZbfrbH05ku2juah9rkBtCU92gtsKjIBiTk1luYl/MO9vhpsVBqeRySihN+Gj0NSbmCI89FNwN4D8yyCgFYEpIR2ZFIGvlLQc47AGtCOCq7yvpGh6UzyoelRzwOyivIip/5ny2dALMNHaGNF7zhTfAVecSQYEmCnFLgB8tvgAUqJ8s80JD3CKxGEUqALXam5RctwCZgkmUKDAuxAajR6Hi15POBfy+YZwS5U7ovBcYPWfs/Ip4/fAgaYLrU4KURAVwE6oF2+4PpnG1J+7c6+5gd9jnTgl6/Vex8AofiJ3Puq10mAAAAAElFTkSuQmCC"
                                alt="play--v1"
                            />
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default SimulatorResults;