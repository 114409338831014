import React from "react";
import { Navigate } from "react-router-dom";
import Base from "./views/Base";
import CentralizeRedirect from "auth/CentralizeRedirect";
import ScrollToTop from "utils/ScrollToTop";
import { useAuth } from "react-oidc-context";

const wrapWithScrollToTop = (component) => <ScrollToTop>{component}</ScrollToTop>;

// Composant qui vérifie si l'utilisateur est authentifié avant d'afficher le contenu
const PrivateRoute = ({ children }) => {
  const auth = useAuth();

  // Si l'utilisateur n'est pas authentifié, rediriger vers la page d'accueil
  if (!auth.isAuthenticated && !auth.isLoading) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// Fonction pour wrapper les composants avec PrivateRoute et ScrollToTop
const wrapWithPrivateRoute = (component) => <PrivateRoute>{wrapWithScrollToTop(component)}</PrivateRoute>;

const routes = [
  {
    path: "/hotel/",
    // element: <Base pageName="Home hôtel" viewID={"hotel"} />,
    element: <Navigate to="/hotel/lunishower/dashboard" replace />,
  },
  {
    path: "/settings/hotel/informations",
    element: wrapWithPrivateRoute(<Base pageName="Mes réglages" viewID={"hotelInformations"} />),
  },
  {
    path: "/settings/hotel/rooms",
    element: wrapWithPrivateRoute(
      // <Base pageName="Gestion des chambres" viewID={"rooms_settings"} />
      <Base pageName="Les chambres" viewID={"rooms"} />
    ),
  },
  {
    path: "/hotel/lunishower",
    element: <Navigate to="/hotel/lunishower/dashboard" replace />,
  },
  {
    path: "/hotel/lunishower/dashboard",
    element: wrapWithPrivateRoute(<Base pageName="Tableau de bord" viewID={"dashboard"} />),
  },
  {
    path: "/hotel/lunishower/consommation",
    element: wrapWithPrivateRoute(<Base pageName="Consommation" viewID={"consommation"} />),
  },
  {
    path: "/hotel/lunishower/economies",
    element: wrapWithPrivateRoute(<Base pageName="Economies" viewID={"economies"} />),
  },
  {
    path: "/hotel/lunishower/rooms",
    element: wrapWithPrivateRoute(<Base pageName="Les chambres" viewID={"rooms"} />),
  },
  {
    path: "/hotel/lunishower/rooms/:roomId",
    element: wrapWithPrivateRoute(<Base pageName="Ma chambre" viewID={"room"} />),
  },
  {
    path: "/hotel/lunishower/donation",
    element: wrapWithPrivateRoute(<Base pageName="Mes dons" viewID={"donation"} />),
  },
  {
    path: "/hotel/lunishower/customer_xp",
    element: wrapWithPrivateRoute(<Base pageName="Mon experience client" viewID={"customer_xp"} />),
  },
  {
    path: "/hotel/lunishower/abonnement",
    element: wrapWithPrivateRoute(<Base pageName="Mon abonnement" viewID={"abonnement"} />),
  },
  {
    path: "/settings/hotel/access",
    element: wrapWithPrivateRoute(<Base pageName="Gestion des accès" viewID={"myAccess"} />),
  },
  {
    path: "/settings/hotel/devices",
    element: wrapWithPrivateRoute(<Base pageName="Gestion des appareils" viewID={"devices"} />),
  },
  {
    path: "/user/group/ajouter",
    element: wrapWithPrivateRoute(<Base pageName="Ajouter un groupe" viewID={"addGroup"} />),
  },
  {
    path: "/user/hotels/ajouter",
    element: wrapWithPrivateRoute(<Base pageName="Ajouter un hôtel" viewID={"addHotel"} />),
  },
  {
    path: "/user/informations",
    element: wrapWithPrivateRoute(<Base pageName="Mes informations" viewID={"personalInformations"} />),
  },
  {
    path: "/user/hotels",
    element: wrapWithPrivateRoute(<Base pageName="Mes hôtels" viewID={"myHotels"} />),
  },
  {
    path: "/user/statistics",
    element: wrapWithPrivateRoute(<Base pageName="Mes statistiques" viewID={"statistics"} />),
  },
  {
    path: "/user/preferences",
    element: wrapWithPrivateRoute(<Base pageName="Mes préférences" viewID={"preferences"} />),
  },
  {
    path: "/user/accessAPI",
    element: wrapWithPrivateRoute(<Base pageName="Accès API" viewID={"accessAPI"} />),
  },
  {
    path: "/user/simulator",
    element: wrapWithPrivateRoute(<Base pageName="Simulateur" viewID={"mySimulator"} />),
  },
  {
    path: "/inscription",
    element: wrapWithPrivateRoute(<Base pageName="Inscription" viewID={"inscription"} />),
  },
  {
    path: "/redirect",
    element: <CentralizeRedirect />,
  },
  {
    path: "/",
    // element: wrapWithScrollToTop(<Base pageName="Mon hôtel" viewID={"main"} />),
    element: wrapWithScrollToTop(<Base pageName="Accueil" viewID={"homepage"} />),
  },
  {
    path: "/hotel/lunishower/simulator",
    element: wrapWithPrivateRoute(<Base pageName="Simulator" viewID={"mySimulator"} />),
  },
  {
    path: "/hotel/lunishower/badges",
    element: wrapWithPrivateRoute(<Base pageName="Badges" viewID={"badges"} />),
  },
  {
    path: "/hotel/lunishower/stats",
    element: wrapWithPrivateRoute(<Base pageName="Stats" viewID={"stats"} />),
  },
  { path: "*", element: <Navigate to="/" replace /> },
  // Add more routes as needed
  // ...
];

export default routes;
