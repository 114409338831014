import React from "react";
import greenmiles from "../img/logo_GM.png";
import Translation from "utils/Translation";

function RewardCard ({ name, description, cost, usageCount, totalGreenmilesSpent }) {
  return (
      <div className="room-card">
        <div className="RoomcardElem">
          <span className="RewardTopText TopRoomCardText">
            <Translation tag={"Reward_name"} />
            <br />
          </span>
          <span className="BottomRoomCardText">{name}</span>
        </div>
        <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Reward_points"} />
            <br />
          </span>
          <span className="BottomRoomCardText">{totalGreenmilesSpent}</span>
        </div>
        <div className="RoomcardElem">
          <span className="TopRoomCardText">
            <Translation tag={"Reward_cost"} />
            <br />
          </span>
          <span className="BottomRoomCardText">
            {cost}
            <img src={greenmiles} alt="greenmiles" className="greenmilesLogo" />
          </span>
        </div>
      </div>
  );
}

export default RewardCard;