import React from "react";
import bronzeIcon from "../img/BronzeBadge.png";
import silverIcon from "../img/SilverBadge.png";
import goldIcon from "../img/GoldBadge.png";
import Translation from "../utils/Translation";

const MonthlyBadges = ({ reductionBadge, consumptionBadge, reductionThresholds, consumptionThresholds }) => {
  const getIcon = (level) => {
    switch (level) {
      case "gold":
        return goldIcon;
      case "silver":
        return silverIcon;
      case "bronze":
        return bronzeIcon;
      default:
        return null;
    }
  };

  const formatBadgeLevel = (level) => {
    switch (level) {
      case "gold":
        return <Translation tag="Badges_Gold" />;
      case "silver":
        return <Translation tag="Badges_Silver" />;
      case "bronze":
        return <Translation tag="Badges_Bronze" />;
      default:
        return <Translation tag="Badges_None" />;
    }
  };

  const formatThreshold = (threshold, suffix = "") => {
    if (!threshold) return "";
    return `+ de ${threshold.toLocaleString("fr-FR")}${suffix}`;
  };

  const reductionData = {
    icon: getIcon(reductionBadge),
    title: formatBadgeLevel(reductionBadge),
    threshold: formatThreshold(reductionThresholds?.[reductionBadge], "%"),
    label: <Translation tag="Badges_Savings" />,
  };

  const consumptionData = {
    icon: getIcon(consumptionBadge),
    title: formatBadgeLevel(consumptionBadge),
    threshold: formatThreshold(consumptionThresholds?.[consumptionBadge], " L"),
    label: <Translation tag="Badges_WaterSaved" />,
  };

  return (
    <div className="monthly-badges-container">
      {[reductionData, consumptionData].map((badge, idx) => (
        <div key={idx} className="badge-wrapper">
          {badge.icon && <img src={badge.icon} alt={badge.title} className="badge-icon" />}
          <div className="badge-card">
            <div className="badge-label">{badge.label}</div>
            <div className="badge-title">{badge.title}</div>
            <div className="badge-threshold">{badge.threshold}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MonthlyBadges;
