import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import Translation from "utils/Translation";

const LineChartComponent_simple = ({ data }) => {
    const [startIndex, setStartIndex] = useState(0);
    const barsPerPage = 6;

    useEffect(() => {
    }, [data]);

    if (!data || data.length === 0) {
        return <p>Pas de données disponibles pour le graphique.</p>;
    }

    const handlePrev = () => {
        if (startIndex > 0) setStartIndex(startIndex - 1);
    };

    const handleNext = () => {
        if (startIndex + barsPerPage < data.length) setStartIndex(startIndex + 1);
    };

    const tooltipFormatter = (value, name) => {
        let unit = "€";

        if(name.props.tag === 'Eau_euro'){
            return [`Eau : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        } else if(name.props.tag === 'Water_euro'){
            return [`Water : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        } else if(name.props.tag === 'Energie_euro'){
            return [`Energie : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        } else if(name.props.tag === 'Energy_euro'){
            return [`Energy : ${value.toLocaleString("fr-FR")} ${unit}`, name];
        }
    };

    const displayedData = data.slice(startIndex, startIndex + barsPerPage);

    return (
        <div style={{ position: "relative", width: "100%", height: "350px" }}>
            <div
                onClick={handlePrev}
                style={{
                    position: "absolute",
                    left: "0",
                    top: "40%",
                    transform: "translateY(-50%)",
                    cursor: startIndex === 0 ? "not-allowed" : "pointer",
                    opacity: startIndex === 0 ? 0.3 : 1,
                    fontSize: "30px",
                    padding: "2px",
                    zIndex: 1,
                    userSelect: "none",
                    color: "#346840"
                }}
            >
                ◀
            </div>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={displayedData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
                >
                    <XAxis dataKey="month" tick={{ fontSize: 14 }} textAnchor="middle" interval={0} />
                    <YAxis />
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend wrapperStyle={{ paddingTop: 10 }} />
                    <Bar
                        dataKey="totalEnergySavedInEuros"
                        stackId="a"
                        fill="#346840"
                        name={<Translation tag={"Energie_euro"} />}
                        isAnimationActive={false}
                        barSize={30}
                    />
                    <Bar
                        dataKey="totalWaterSavedInEuros"
                        stackId="a"
                        fill="#106198"
                        name={<Translation tag={"Eau_euro"} />}
                        isAnimationActive={false}
                        barSize={30}
                    />
                </BarChart>
            </ResponsiveContainer>

            <div
                onClick={handleNext}
                style={{
                    position: "absolute",
                    right: "0",
                    top: "40%",
                    transform: "translateY(-50%)",
                    cursor: startIndex + barsPerPage >= data.length ? "not-allowed" : "pointer",
                    opacity: startIndex + barsPerPage >= data.length ? 0.3 : 1,
                    fontSize: "30px",
                    padding: "2px",
                    zIndex: 1,
                    userSelect: "none",
                    color: "#346840"
                }}
            >
                ▶
            </div>
            {/* <button onClick={handlePrev} disabled={startIndex === 0}>
                ◀
            </button>
            <button onClick={handleNext} disabled={startIndex + barsPerPage >= data.length}>
                ▶
            </button> */}
        </div>
    );
};

export default LineChartComponent_simple;
