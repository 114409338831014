import FormHotel from "component/FormHotel";
import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import ContenerSaaS from "utils/ContenerSaaS";
import Translation from "utils/Translation";

const Reglages = () => {
    const keycloak = useAuth();
    const { hId } = useContext(HotelContext);
    const [hotel, setHotel] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [reload, setReload] = useState(false);

    const fetchHotel = () => {
        if (!keycloak.isAuthenticated || !keycloak.user?.access_token) {
            setHasError(true);
            setErrorMessage("Veuillez vous connecter pour accéder aux informations de l'hôtel.");
            setLoading(false);
            return;
        }

        setLoading(true);
        fetch("/api/hotels/" + hId, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.user.access_token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoading(false);
                setHotel(data.hotel);
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (keycloak.isAuthenticated && hId) {
            fetchHotel();
        } else {
            setHotel([]);
        }
    }, [keycloak.isAuthenticated, hId]);

    return (
        <ContenerSaaS title="Hotel_Info" notMaxHeight>
            <div>
                {!keycloak.isAuthenticated ? (
                    <div><Translation tag={"hotel_access"}/></div>
                ) : isLoading ? (
                    <div><Translation tag={"Loading"}/></div>
                ) : hasError ? (
                    <div><Translation tag={"Erreur"}/></div>
                ) : !hotel || hotel.length === 0 ? (
                    <div><Translation tag={"No_hotel"}/></div>
                ) : (
                    <FormHotel
                        mode="update"
                        hotel={hotel}
                        setReload={setReload}
                    />
                )}
            </div>
        </ContenerSaaS>
    );
};

export default Reglages;
